@mixin SettingCard {
	display: flex;
    flex-direction: row;
    height: 70px;
    width: 100%;
	max-width: var(--container-max-width);
	margin: 10px auto;
    border-radius: 80px;
    background-color: #E5F7FE;
    color: #1E1C59;

    .SettingCard-contents{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        vertical-align: middle;
        padding: 15px;
        width: 100%;

        .left {
            border-radius: 20px;
            width: 15%;
        }

        .middle{
            text-align: center;
            align-self: center;
            width: 70%;

            #details{
                font-size: 12px;
                font-weight: 300;
            }

          h5 {
            font-size: 19px;
            font-weight: 500;
          }
        }

		.right{
			position: relative;
			width: 15%;
		}
    }
}

.SettingCard-container{
    @include SettingCard;
}

.SettingCard-container-small{
    @include SettingCard;
	height: 40px;
	width: 75%;
	max-width: 500px;
}