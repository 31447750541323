@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300;700&display=swap');
:root {
    color-scheme: light only;
}

html {
    margin: 0;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
}
body {
    margin: 0;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;

}

.body-webview {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.body-scroll {
    height: unset !important;
    width: unset !important;
    overflow-x: hidden;
    overflow-y: auto !important;
}

.font-family-poppins-300{
	font-family: 'Signika';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
}

.font-family-poppins-700-bold{
	font-family: 'Signika';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
}

.App {
  text-align: center;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

  font-family: Signika, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rs-check-tree-node > .rs-check-tree-node-label .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
	color: #05AFF2;
}

:root {
	--container-max-width: 700px;
}

.modal-add-location{
    margin-top: 10vh;
    width: 90vw;
}

@keyframes zoominoutsinglefeatured {
    0% {
        transform: scale(0.9,0.9);
    }
    50% {
        transform: scale(1,1);
    }
    100% {
        transform: scale(0.9,0.9);
    }
}

@keyframes zoominoutsinglefeaturedhigher {
    0% {
        transform: scale(0.8,0.8);
    }
    50% {
        transform: scale(1,1);
    }
    100% {
        transform: scale(0.8,0.8);
    }
}

@keyframes opacityinoutsingle {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.grid-row {
    display: flex;
    flex-direction: row;
}

.grid-col {
    flex:1;
}

.rs_expandable {
    display: flex !important;
    flex-direction: column-reverse !important;
}

.rs_expandable .rs-dropdown-menu {
    position: relative !important;
    top: 0px !important;

}


/* Keyframes for the zoom in zoom out animation */
@keyframes zoom-in-zoom-out {
    /* At the beginning of the animation */
    0% {
        /* Scale the element to its original size */
        transform: scale(1, 1);
    }
    /* At the middle of the animation */
    50% {
        /* Scale the element to 1.5 times its original size */
        transform: scale(1.5, 1.5);
    }
    /* At the end of the animation */
    100% {
        /* Scale the element back to its original size */
        transform: scale(1, 1);
    }
}


.rs-picker-menu {
    z-index: 1200 !important;
}

.custom-questions-cont .rs-check-tree-node-text-wrapper {
    font-weight: 300;
}


.GeneralScreen-container{
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-family: 'Signika', sans-serif;
    font-weight: 700;
    font-style: normal;

    .GeneralScreen-contents{
        display: flex;
        flex-direction: column;
        height: 79dvh;
        width: 90vw;
        max-width: var(--container-max-width);
        margin: 0px auto;
    }

    .GeneralScreen-contents::-webkit-scrollbar{
        display: none;
    }

}
