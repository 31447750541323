.MyInvites-container{
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  width: 100vw;

  .MyInvites-TopText{
    display: flex;
    width: 100%;
    color: white;
    background: rgb(33,29,91);
    background: linear-gradient(42deg, rgba(33,29,91,1) 0%, rgba(38,91,196,1) 36%, rgba(0,146,168,1) 68%, rgba(0,149,214,1) 100%);
    align-items: center;
    justify-content: center;
  }

  .MyInvites-contents{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    height: calc(80dvh - 34px);
    width: 90vw;
    max-width: var(--container-max-width);
    overflow-y: scroll;

    .Events-Filter{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 43px;
      max-height: 43px;
      min-height: 43px;
      max-width: var(--container-max-width);
      width: 90vw;
      margin: 5px auto;
      background-color: rgb(232, 231, 237);
      border-radius: 10px;
      overflow-x: scroll;

      @mixin css($color: white) {
        border-radius: 10px;
        font-size: 11px;
        padding: 6px 13px;
        color: $color;
        flex:1;
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      .Events-Status-Option{
        @include css($color: grey);
        font-size: 15px;
        white-space: nowrap;
      }

      .Events-Status-Choice{
        @include css();
        font-size: 15px;
        background-color: rgb(6, 175, 242);
        white-space: nowrap;
      }

      .Events-Filter-Option{
        @include css($color: grey);
        white-space: nowrap;
      }

      .Events-Filter-Choice{
        @include css();
        background-color: rgb(6, 175, 242);
        white-space: nowrap;
      }
    }

    .Events-Filter::-webkit-scrollbar{
      display: none;
    }

    .invite{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 300px;
      margin: 20px auto;
      border: 5px solid #221d5d;
      border-radius: 10px;

      .invite-state{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-self: center;
        width: 140px;
      }

      .bottom-buttons .add-button {
        width: unset;
      }
      button {
        min-width: 200px;
      }
    }

  }

  .MyInvites-contents::-webkit-scrollbar{
    display: none;
  }
}
