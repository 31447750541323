.SaveTheDate{
	display: flex;
	flex-direction: column;
	min-height: 100dvh;
	overflow: hidden;
	height: 100%;

	.PreviewSaveTheDate-container{
		min-height: 70dvh;
		width: 100%;
		background-size: cover;
		margin: 0px auto;

		.PreviewSaveTheDate-contents{
			min-height: 70dvh;
			width: 100%;
			padding-right: 15px;
			padding-left: 15px;
			color: white;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			word-break: break-word;

			.description{
				height: 50%;
				overflow-y: scroll;
			}

			.description::-webkit-scrollbar{
				display: none;
			}
		}
	}

	.Answer{
		display: flex;
		flex-direction: column;
		margin-bottom: 40px;
		margin-top: 20px;
		padding-right: 15px;
		padding-left: 15px;

		.Options{
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			margin-top: 20px;

			@mixin option {
				width: 31%;
				color: white;
				font-size: 15px;
				font-weight: 600;
				border-radius: 20px;
				padding: 20px 5px;

			}

			.Yes{
				@include option;
				background-color: #05AFF2;
			}

			.No{
				@include option;
				background-color: red;
			}

			.NotSure{
				@include option;
				background-color: rgb(255, 123, 0);
			}
		}
	}
}


.SaveTheDateModal {

	.data-row{
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin: 10px 0 30px 0;

		@mixin option {
			text-align: center;
			width: 30%;
			padding: 10px 5px;
			border-radius: 20px;
		}

		.active-option{
			@include option;
			background-color: #05AFF2;
			color: #FFFFFF
		}

		.inactive-option{
			@include option;
			background-color: #E5F7FE;
			color: #6B6996;
		}
	}
}

@keyframes revealInvite {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.new-invite-container {
	display: flex;
	flex-direction: column;
	background-image: url("/images/bg-invite-top.jpg");
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: top center;
	min-height: 100dvh;
	//animation: revealInvite 2s cubic-bezier(0.77, 0, 0.175, 1) forwards;
	//opacity: 0;

	.new-invite-container-inner {
		display: flex;
		flex-direction: column;
		background-image: url("/images/bg-invite-bottom.jpg");
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: bottom center;
		flex:1;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 99;
		width: 100vw;
		min-height: 100dvh;
		.invite-container{
			z-index: 101;
		}
	}

	&.new-invite-container-intro-screen {
		height: 100dvh;
		z-index: 200;
		position: absolute;
		width: 100%;

		.invite-container {
			height: 100%;
		}
		.new-invite-container-inner{
			z-index: 101;
		}
	}

	.Form{
		display: flex;
		flex-direction: column;
		padding: 30px 20px;
		background-color: #fff;

		.data-row{
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			margin: 10px 0px;

			@mixin option {
				width: 30%;
				padding: 5px;
				border-radius: 20px;
			}

			.active-option{
				@include option;
				background-color: #05AFF2;
				color: #FFFFFF
			}

			.inactive-option{
				@include option;
				background-color: #E5F7FE;
				color: #6B6996;
			}
		}

		.CustomQuestionCard{
			display: flex;
			flex-direction: column;
			margin: 20px 0px;
			overflow-y: scroll;

			.rs-checkbox-checker {
				width: 200px;
			}
		}
	}

	.Answer{
		display: flex;
		flex-direction: column;
		padding-bottom: 40px;
		padding-top: 20px;
		padding-right: 15px;
		padding-left: 15px;
		background-color: #ffffff;

		.Options{
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			margin-top: 20px;

			@mixin option {
				width: 100%;
				color: white;
				font-size: 15px;
				font-weight: 600;
				border-radius: 20px;
				padding: 20px 5px;
				cursor: pointer;
				margin-bottom: 0.8rem;

			}


			.Yes{
				@include option;
				background-color: #00bb12;
			}

			.No{
				@include option;
				background-color: #ee2d2d;
			}

			.NotSure{
				@include option;
				border: 1px solid rgb(255, 123, 0);
				background-color: rgb(255, 123, 0)
			}
			.grey {
				opacity: 0.3 !important;
			}
		}
	}

	.invite-container {
		width: 100%;
		max-width: 700px;
		overflow: hidden;
		margin: 0 auto 0 auto;
	}

	.btn-back {
		margin-bottom: 4rem;
	}
}

.invite-mobile-back-button {
	position: fixed;
	top: 10px;
	left: 10px;
	z-index: 1000000;

	.back-icon {
		font-size: 35px;
		padding: 5px
	}
}

.inviteHiddenCont {
	position: relative;
	height: 100dvh;
	min-height: 100dvh;
	overflow: hidden;
	z-index: 43;
}

@media screen and (min-width:700px) {
	.new-invite-container {
		.invite-container {
			margin: 1rem auto 1rem auto;
			max-width: 700px;
			min-width: 700px;
			border: 1px solid #ccc;
			border-radius: 10px;
		}
	}
}

@media screen and (max-width: 991px) {
	.inviteHiddenCont {
		height: calc(var(--dvh, 1dvh) * 100);
		min-height: calc(var(--dvh, 1dvh) * 100);
	}

	&.intro-screen {
		min-height: unset !important;
		height: calc(var(--dvh, 1dvh) * 100) !important;
		overflow: hidden;

		.intro-container {
			height: unset;
		}
	}
	.new-invite-container.new-invite-container-intro-screen {
		height: calc(var(--dvh, 1dvh) * 100) !important;
		min-height: calc(var(--dvh, 1dvh) * 100) !important;
		overflow: hidden;

		.new-invite-container-inner {
			height: calc(var(--dvh, 1dvh) * 100) !important;
			min-height: calc(var(--dvh, 1dvh) * 100) !important;
		}
	}
}

