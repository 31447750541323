.InviteNavBar-container {
  color: white;
  height: 10dvh;
  width: 100vw;
  z-index: 500;
  font-family: Signika, sans-serif;
  font-weight: 500;
  font-style: normal;
  overflow: hidden;
  max-height: 90px;

  &.absolute {
    z-index: 100010;
    overflow: unset;

    .InviteNavBar-contents {
      overflow: unset;
    }
  }

  .InviteNavBar-contents {
    //box-shadow: -1px -12px 30px 5px rgba(0, 0, 0, 0.25);
    background: rgb(33, 29, 91);
    //background: linear-gradient(42deg, rgba(33, 29, 91, 1) 0%, rgba(38, 91, 196, 1) 36%, rgba(0, 146, 168, 1) 68%, rgba(0, 149, 214, 1) 100%);
    background: linear-gradient(42deg, rgba(0, 149, 214, 1) 0%, rgba(0, 146, 168, 1) 36%, rgba(38, 91, 196, 1) 68%, rgba(33, 29, 91, 1) 100%);
    border-radius: 40px 40px 0px 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10dvh;
    justify-content: space-between;
    //overflow: hidden;
    font-size: 12px;
    animation: slide-up 1s forwards;
    max-width: 900px;
    margin: auto;
    max-height: 90px;

    .menu-element, .active-element {
      position: relative;
      cursor: pointer;

      &:first-of-type {
        border-radius: 40px 0 0 0;
      }

      &:last-of-type {
        border-radius: 0 40px 0 0;
      }

      .icon-row {
        flex:1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 3px;
      }
      .text-row {
        flex:1;
        width: 75px;
        margin:auto;
        padding-top:3px;
      }
      .walkthrough-arrow-cont {
        position: absolute;
        top: -80px;
        left: -30px;
        z-index: 10000000;

        .walkthrough-arrow {
          img {
            width: 50vw;
            height: auto;
            max-width: 90px;
            animation: zoominoutsinglefeaturedhigher 1s infinite;
          }
        }

      }
    }

    .menu-element {
      display: flex;
      flex: 1;
      flex-direction: column;
      //width: 75px;
      padding: 10px;
    }

    .active-element {
      display: flex;
      flex: 1;
      flex-direction: column;
      //width: 75px;
      //color: #05AFF2;
      //background-color: rgba(255, 255, 255, 0.1);
      background: rgba(33, 29, 91, 0.5);
      padding: 10px;
    }
  }
}

@media screen and (max-width: 900px) {
  .InviteNavBar-container {
    .InviteNavBar-contents {
      font-size: 10px;

      .menu-element, .active-element {
        width: 75px;

        .text-row {
          width: unset;
          margin: unset;
        }
      }
    }
  }
}

@media screen and (max-height: 700px) {
  .InviteNavBar-container {
    .InviteNavBar-contents {
      font-size: 10px;

      .menu-element, .active-element {
        .icon-row {
          padding-top:1px;
        }
        .text-row {
          padding-top:2px;
        }
      }
    }
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}
