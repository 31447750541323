.WebsiteInviteModuleDemo-intro-modal {
  margin: 0.5rem 0 0 0;

  .cards-title {
    margin: 1.5rem 0 1rem 0;
    text-align: center;
  }

  .cards {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;


    .cards-item {
      width: 50%;
      background: #e5f7fe;
      border-radius: 0.5rem;
      padding: 2rem 0;
      cursor: pointer;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:hover {
        background: #06aff2;
        color: #fff !important;

        .icon {
          &.first, &.second {
            color: #fff;
          }
        }
      }

      .image {
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 0.5rem;

        .icon {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0;

          &.first {
            color: #06aff2;
          }
          &.second {
            color: #e8630a;
          }

          i {
            font-size: 1.8rem;
            animation: zoom-in-zoom-out 1.5s ease infinite;
          }
        }

        img {
          width: 100%;
          max-height: 170px;
        }
      }


      .title {
        font-size: 1rem;
        font-weight: 600;
        padding: 0.5rem 0.5rem;
      }
    }

  }
}

