// .CustomQuestions-container{
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: space-between;
// 	height: 100dvh;
// 	width: 100vw;

	.CustomQuestions-contents{
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;

		.Title{
			margin-bottom: 10px;
			margin-top: 20px;
			font-size: 18px;
		}

		.CustomQuestions{
			display: flex;
			flex-direction: column;
			height: auto;
			// width: 90vw;

			.buttons{
				display: flex;
				flex-direction: row;
				justify-content: center;

				@mixin button {
					width: 50px;
					padding: 10px;
					border-radius: 20px;
					font-weight: 700;
				}

				.button-active{
					@include button;
					color: white;
					background-color: #05AFF2;
				}

				.button-inactive{
					@include button;
					color: #6B6996;
					background-color: #E5F7FE;
				}
			}

			.CustomQuestion-Card{
				display: flex;
				flex-direction: column;
				width: 90vw;
				max-width: var(--container-max-width);
				border: 0.5px solid #E5F7FE;
				color: #1E1C59;
				border-radius: 10px;
				margin: 20px auto;
				text-align: left;
				padding: 20px;

				.lang-indicator {
					float: right;
					display: block;
				}

				.dropdown_scroll{
					margin: 20px auto;
					color: black;
					background: #E5F7FE;
					border-radius: 10px;
				}

				.rs-input{
					border: none;
					background: #E5F7FE;
				}

				.Add-Option{
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					margin-right: 12px;

					.Add-Icon{
						font-size: 13px;
						color: white;
						background-color: #05AFF2;
						// border: 1px solid green;
						border-radius: 50%;
						padding: 5px;
					}
				}

				.InputGroup-Container{
					display: flex;
					flex-direction: row;
					height: 43px;
					margin: 10px 0px;

					.InputGroup-Icon{
						.Del-Icon{
							font-size: 10px;
							color: red;
							border: 1px solid red;
							border-radius: 50%;
							padding: 5px;
						}
					}
				}

				.Add-Delete-Icons{
					display: flex;
					flex-direction: row;
					width: 100%;
					justify-content: space-evenly;
				}
			}
		}

		// .CustomQuestions::-webkit-scrollbar{
		// 	display: none;
		// }
    }
// }
