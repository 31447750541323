.CreateEvent-container{
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Signika', sans-serif;
  font-weight: 700;
  font-style: normal;

  .Footer{
    width: 90%;
    margin: auto;

    .add-button{
      height:6dvh;
      border-radius: 20px;
      width: 100%;
      margin-bottom: 10px;
      font-size: 20px;
      font-family: 'Signika', sans-serif;
      font-weight: 700;
      font-style: normal;
    }

    #disclaimer{
      font-size:10px;
    }
  }

  .CreateEvent-contents{
    display: flex;
    flex-direction: column;
    height: 80dvh;

    .section-title {
      font-size: 22px;
      margin-bottom: 50px;
      margin-top: 20px;
    }

    .progress-bar {
      position: relative;
      color: white;
      height: 15px;
      box-shadow: 1px 1px 0 0 rgba(0,0,0,0.15);
      overflow: hidden;

      .inner {
        background: rgb(33,29,91);
        background: linear-gradient(42deg, rgba(33,29,91,1) 0%, rgba(38,91,196,1) 36%, rgba(0,146,168,1) 68%, rgba(0,149,214,1) 100%);
        height: 100%;
        transition-duration: 1s;
      }
    }

    .field-title {
      text-align: left;
      margin-bottom: 5px;
    }

    .organizer-type-selector {
      display: flex;
      flex-direction: row;
      margin: 0 0 10px 0;

      .item {
        flex:1;
        background-color: #E5F7FE;
        color: #221d5d;
        padding: 10px 0;
        border-radius: 5px;
        margin-right: 10px;

        &.active {
          color: #ffffff;
          background-color: #221d5d;
        }

        &:last-of-type {
          margin-right:0
        }
      }
    }

    .Title{
      width: 100%;
      color: white;
      background: #211d5b;
      background: linear-gradient(42deg, #211d5b 0%, #265bc4 36%, #0092a8 68%, #0095d6 100%);
    }

    .rs-uploader-text .rs-uploader-file-items{
      margin-top: 0px;
    }

    .Input_Center{
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 92%;

      .Input_Type_Options{
        flex:1;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        overflow-y: scroll;
        max-width: 900px;
        margin: auto;

        .Input_Type_Option{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          color: #221d5d;
          cursor: pointer;

          &:hover {
            .Circle {
              background-color: #93ceec;

              img {
                transform: translateY(-5px);
                transition-duration: 0.5s;
              }
            }
          }


          .Circle{
            height: 120px;
            width: 120px;
            margin: 10px 40px;
            border-radius: 50%;
            background-color: #E5F7FE;
            transition-duration: 0.5s;


            img {
              position: relative;
            }
          }
        }
      }

      .Input_Type_Options::-webkit-scrollbar{
        display: none;
      }
    }

    .input-zone{
      display:flex;
      flex: 1;
      flex-direction: column;

      text-align: center;
      overflow-y: scroll;
      width: 90vw;
      max-width: var(--container-max-width);
      margin: 0px auto;


      .tutorial {
        font-size: 16px;
        margin: 30px 0 30px 0;
      }

      .Options-Row{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 10px 0px;

        @mixin option {
          width: 50px;
          padding: 10px;
          border-radius: 20px;

          &:first-of-type {
            margin-right: 20px;
          }
        }

        .active-option{
          @include option;
          background-color: #05AFF2;
          color: #FFFFFF
        }

        .inactive-option{
          @include option;
          background-color: #E5F7FE;
          color: #6B6996;
        }
      }

      .controls {
        display: flex;
        flex-direction: row;
        margin-top: 2rem;

        .bottom-buttons {
          width: 50%;
          margin: unset;


          .add-button {
            width: 100%;
          }

          .back-button {
            width: 100%;
            margin-right: 10px;
          }

        }

      }

      .InputGroup-Container{
        margin: 10px 0px;
      }

      .rs-picker-toggle .rs-picker-toggle-caret{
        display: none;
      }

      .rs-picker-toggle .rs-picker-toggle-placeholder{
        color: #221d5d;
        padding-left: 0px;
        font-family: 'Signika', sans-serif;
        font-weight: 700;
        font-style: normal;
      }

      .rs-input{
        color: #221d5d;
      }

      .rs-input::placeholder{
        color: #221d5d;
      }

      .AddJob-Icon{
        color: #221d5d;
      }

      #job-select::placeholder{
        padding-left:10px;
      }

      #job-select{
        background: transparent;
        color: #221d5d;
        border: none;
        border-radius: 0px;
        // border-bottom: 1px solid #221d5d;
        width : 100%;
        display: flex;
        flex-direction: row;
        font-family: 'Signika', sans-serif;
        font-weight: 700;
        font-style: normal;
      }

      .rs-input{
        color: #221d5d;
      }

      .AddHours-date-picker{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: 'Signika', sans-serif;
        font-weight: 700;
        font-style: normal;

        .Overlay{
          position: absolute;
          top: -10px;
          margin-left: 10px;
          padding: 0px 5px;
          font-size: 12px;
          color: black;
          background-color: white;
        }

        .rs-picker-toggle-value{
          font-family: 'Signika', sans-serif;
          font-weight: 700;
          font-style: normal;
          color: #221d5d;
        }

        .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean, .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean{
          display: none !important;
        }

        .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn, .rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn{
          background-color: #E5F7FE;
        }
      }

      .AddJob-Container{
        margin-bottom: 10px;
        border-radius: 10px;

        .rs-picker-toggle-value{
          font-family: 'Signika', sans-serif;
          font-weight: 700;
          font-style: normal;
          color: #221d5d;
        }

        .rs-picker-toggle .rs-picker-toggle-placeholder{
          color: #221d5d;
        }
      }

      #input{
        background: transparent;
        color: #221d5d;
        border: none;
        border-radius: 0px;
      }

      #inputText{
        height: 13dvh;
        padding-left:35px;
      }

      #input::placeholder{
        color: #221d5d;
      }

      #inputText::placeholder{
        color: #221d5d;
      }

      .Locations{
        .location{
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          margin: 20px 0px;

          @mixin line {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            text-align: left;
            width: 100%;
            padding: 10px 20px;
            font-size: 15px;
            text-align: center;
          }

          .header{
            @include line;
            font-weight: bold;
            font-size: 15px;
            background-color: #05aff2;
          }

          .uneven-line{
            @include line;
            background-color: #e5f7fe;
          }

          .even-line{
            @include line;
            background-color: #d1e0ed;
          }
        }

        .location :first-child{
          border-radius: 20px 20px 0px 0px;
        }

        .location :last-child{
          border-radius: 0px 0px 20px 20px;
        }
      }
    }

    .input-zone::-webkit-scrollbar{
      display: none;
    }

  }
}

@media screen and (max-width: 700px) {
  .CreateEvent-container{
    .CreateEvent-contents{

      .section-title {
        font-size: 22px;
      }

      .Input_Center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 92%;

        .Input_Type_Options {
          flex-direction: row;
          flex-wrap: wrap;
          display: flex;

          .Input_Type_Option {
            width: 40vw;
          }
        }
      }

    }
  }
}
