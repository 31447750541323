.invites-modal{

    height: 77dvh;
    width: 85vw;
    border-radius: 10px;
    margin-top: -1px;

    .modal-content{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;


        #code{
            margin-top: 5dvh;

            p{
                text-align: center;
                font-weight: 700;
                font-size: 40px;
            }
        }

        #invites{
            margin-top: 5dvh;

            p{
                font-size: 20px;
            }

            #icons{
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                #whatsapp{
                    width: 70px;
                }
                #messenger{
                    width: 60px;
                }
            }
            
        }
        
    }
}


.std-modal{

    height: 75dvh;
    width: 85vw;
    border-radius: 10px;

    .modal-content{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;


        

        #code{
            margin-top: 5dvh;

            p{
                text-align: center;
                font-weight: 700;
                font-size: 40px;
            }
        }

        #invites{
            margin-top: 5dvh;

            p{
                font-size: 20px;
            }

            #icons{
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                height: 10dvh;

                #whatsapp{
                    width: 70px;
                    height: auto;
                }
                #messenger{
                    width: 60px;
                    height: auto;
                }
            }
            
        }
        
    }
}