.locations-container-style-comp {
    display: flex;
    flex-direction: column;
    border: 2px solid rgb(229, 247, 254);
    //border-right: 0;
    overflow: hidden;

    &.border-bottom-radius {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .controls {
        display: flex;
        flex-direction: row;

        .text-style-col {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            text-align: center;
            border: 2px solid transparent;
            cursor: pointer;
            height: 30px;
            border-radius: 10px;

            &:hover, &.selected {
                border: 2px solid #ffffff;
                background: rgb(229, 247, 254);
            }

            .text-style-col-title {
                font-size: 0.6rem;
                margin-right: 5px;
            }
        }

        .text-style-color-swatch {
            width: 14px;
            height: 14px;
            background: #000000;
            border-radius: 7px;
            border:1px solid #ccc;
        }
    }

    .sections {

        .selector {
            padding: 10px;
            max-height: 80px;
            overflow-y: auto;

            &.selector-row {
                display: flex;
                flex-direction: row;

                .selector-item {
                    display: flex;
                    flex:1;
                    justify-content: center;
                }
            }

            .selector-item {
                cursor: pointer;
                padding:3px 5px;
                border-radius: 5px;

                &.selected {
                    background: rgb(229, 247, 254);
                }
            }
        }

    }

}
