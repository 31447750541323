.Event-FeedBox-container{
	display: flex;
	width: 100%;
	flex-direction: column;
	overflow-y: scroll;

	@mixin post($color: white, $backgroundColor: white) {
		display: flex;
		flex-direction: column;
		margin-top: 10px;
		text-align: left;
		border-radius: 10px;
		padding: 5px 10px;
		color: $color;
		background-color: $backgroundColor;

		.message{
			display: flex;
			flex-direction: column;
		}
	}

	.FeedBox-Post-User{
		@include post(#E8E7EE, #05AFF2);

		.timestamp {
			text-align: right;
			font-size: 12px;
		}
	}

	.FeedBox-Post-Others{
		@include post(#6B6996, #E5F7FE);

		.timestamp {
			text-align: right;
			font-size: 12px;
			color: #000
		}
	}
}

.Event-FeedBox-container::-webkit-scrollbar {
		display: none;
}

.FeedBox-ChatBubble-User a {color: E8E7EE;}

.FeedBox-ChatBubble-User a:focus {color: E8E7EE;}

.FeedBox-ChatBubble-Others a {color: #6B6996;}

.FeedBox-ChatBubble-Others a:focus {color: #6B6996;}

.poza{
	display: flex;
	width: 30dvh;
	height: 30dvh;
}
