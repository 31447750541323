.ResetPage-container{
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
	width: 100vw;

    .ResetPage-contents{
        display: flex;
        flex-direction: column;
        // justify-content: space-around;
		align-items: center;
        height: 77dvh;
		max-width: 500;
    }

	.style-inputs{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	.style-width{
		max-width: 500px;
		width: 70vw;
	}
}