.TestimonialsTab-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  margin: 0px auto;
  border-radius: 10px;


  .TestimonialsTab-content{
    margin-top: 10px;
  }
}