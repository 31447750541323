.Tickets-container{
	min-height: 70dvh;
	margin: 0px auto;
	color: #221d5d;
	overflow-y: scroll;

	.Tickets-tab-options{
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-evenly;
		font-size: 25px;
		height: 75px;
	}

	.Tickets-content{
		.section{
			background-color: #E5F7FE;
			padding: 5px;
			border-radius: 10px;
			margin-bottom: 10px;
			text-align: left;

			.description{

			}

			.delete{
				display: flex;
				justify-content: flex-end;
			}

			.options{
				display: flex;
				flex-direction: row;

				@mixin option {
					border-radius: 10px;
					padding: 5px 20px;
					margin: 20px 10px;
					background-color: #05AFF2;
					color: white
				}

				.active{
					@include option;
				}

				.default{
					@include option;
					background-color: #addaec;
					color: #6B6996;
				}
			}
		}

		.ticket{
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			min-height: 500px;
			width: 300px;
			margin: 20px auto;
			border: 5px solid #221d5d;
			border-radius: 10px;

			.ticket-state{
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;
				align-self: center;
				width: 140px;
			}
			.bottom-buttons .add-button {
				width: unset;
			}
			button {
				min-width: 200px;
			}
		}

		.button{
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: space-evenly;
		}

		.rs-dropdown{
			width: 100%;
		}

		.rs-dropdown-toggle, .rs-dropdown-toggle.rs-btn{
			width: 100%;
			max-width: 480px;
		}

		.rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu, .rs-dropdown-open.rs-dropdown-placement-bottom-end > .rs-dropdown-menu{
			width: 100%;
			text-align: center;
		}
	}
}

.Tickets-container::-webkit-scrollbar{
	display: none;
}

// @media screen and (min-width: 450px) {
// 	.Tickets-container{
// 		text-align: left;
// 	}
// }
