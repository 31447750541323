.FeedTab-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    height: 70dvh;
    margin: 0px auto;
    border-radius: 10px;

    .uploader{
        margin-left: 6px;
    }

	#sendMsg{
		margin-top: 20px;
		font-size: 15px;
		font-weight: 700;
	}

	.FeedTab-Feed {
		display: flex;
		height: calc(70dvh - 50px);
		margin-bottom: 10px;
	}

    .FeedTab-Input{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
		align-items: center;
        margin: 10px 0px;

		.rs-uploader-picture .rs-uploader-trigger-btn{
			margin: 0px !important;
			height: 40px;
			width: 40px;
		}
    }

    #input-text{
        display: flex;
        flex-direction: row;
        width: 100%;

        textarea.rs-input{
			min-width: 75%;
		}
    }
}
