video {
    max-width: 90vw;
    max-height: 70dvh;
    margin: auto;
    border-radius: 20px;
}

.overlay-pic{
    position: absolute;
    top: 0dvh;
    right: 0;
    bottom: 0;
    height: 70dvh;
    width: 100%;
    background: rgba(0,0,0,0.6);
    text-align: center;
    border-radius: 20px;
}

.Selfie-container{
	display: flex;
	position: relative;
    flex-direction: column;
    height: 70dvh;
	width: 90vw;
	max-width: 500px;
    margin: 0px auto;
    border-radius: 10px;
    background-color: white;
}

.circle-pic{
    position: absolute;
    top: 30dvh;
    right: 0;
    bottom: 0;
    left: 50%;
    background-color: rgba(255, 255, 255, 0.199);
	transform: translate(-50%, -50%);
    height: 310px;
    width: 200px;
    border-radius: 50%;
}

.modal-code{
    height: 70dvh;
    border-radius: 10px;
}

.Logo-one{
    #logo{
        
        width: 120px;
        height:120px;
        img{
            width: 120px;
            height:120px;
        }
    }
}

.img-webView{
	transform: scaleX(-1);
}