.VouchersTab-container{
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0px auto;
  border-radius: 10px;


  .VouchersTab-Feed {
    display: flex;
    flex-direction: column;

    .Events-Card-Container{
      display: flex;
      flex-direction: column;
      max-width: var(--container-max-width);
      width: 90vw;
      margin: 10px auto;
      border-radius: 10px;
      background-color: #E8E7EE;
      color: #1E1C59;
      position: relative;
      overflow:hidden;

      .redeemed-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        background-color: rgba(0,0,0,0.5);
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;

        .text {
          color: #fff;
          font-weight: 700;
          font-size: 30px;
          transform: rotate(45deg);
          user-select: none;
        }
      }

      .maps-buttons {
        display: flex;
        flex-direction: row;

        padding: 0 5px 10px 5px;

        button {
          border-radius: 10px !important;
        }
      }

      .redeemed {
        background-color: #29a63b;
        color: white;
      }

      .Picture{
        height: 150px;
        width: 100%;
        border-radius: 10px 10px 0px 0px;
      }

      .Open-Event{
        display: flex;
        justify-content: center;
        background-color: #05AFF2;
        color: white;
      }

      .Title{
        text-align: left;
        font-size: 20px;
        margin-left: 5px;
        margin-top: 10px;
      }

      .Icons{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        overflow: scroll;

        @mixin event{
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          color: white;
          min-width: 80px;
          height: 22px;
          font-size: 11px;
          border-radius: 5px;
          margin: 5px;
          padding: 1px 3px;
        }

        .Data{
          @include event;
          background-color: #05AFF2;
          padding: 2px 10px;
          white-space: nowrap;
        }

        .New{
          @include event;
          background-color: red;
          padding: 2px 10px;
        }

        .PublicEvent{
          @include event;
          background-color: #2879F6;
        }

        .PrivateEvent{
          @include event;
          background-color: #70163C;
        }

        .Delete{
          @include event;
          border: 1px solid #F26157;
          color: #F26157;
        }

        .Share{
          @include event;
          background-color: #19B899;
        }
      }

      .Description{
        text-align: left;
        font-size: 14px;
        margin: 5px;
        max-width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .Full-Description{
        text-align: left;
        margin: 5px;
        font-size: 14px;
        white-space: pre-line;
        text-overflow: ellipsis;
        word-break: break-word;
      }

      .Icons::-webkit-scrollbar{
        display: none;
      }
    }

    .Events-Card-Container :first-child{
      border-radius: 10px 10px 0px 0px;
    }


  }

}
