.Invitation{
	display: flex;
	flex-direction: column;
	min-height: 100dvh;
	overflow: hidden;

	.MainInvite-container{
		width:100%;
		background-size: cover;

		.MainInvite-contents{
			min-height: 100dvh;
			color: white;
			word-break: break-word;
			padding: 2dvh 15px 10dvh 15px;


			&.old {
				min-height: unset !important;
				.calendar-container {
					width:350px
				}

				.Answer {
					width: 100%;
				}
			}

			.description{
				height: 50%;
				overflow-y: scroll;
			}

			.description::-webkit-scrollbar{
				display: none;
			}

			// .react-add-to-calendar__dropdown{
			// 	font-family: co-headline,sans-serif;
			// 	font-weight: 400;
			// 	font-style: normal;
			// 	font-size: 4vw;
			// 	border-radius: 0px 0px 10px 10px;
			// 	width: 100%;
			// 	left: 0px;
			// 	border:none;

			// 	@media screen and (min-width: 603px){
			// 		font-size: 2vw;
			// 		margin-top: 2dvh;
			// 	}

			// 	li:nth-child(-n + 2){
			// 		margin-bottom: 2dvh;
			// 	}
			// }

			// .react-add-to-calendar__wrapper{
			// 	font-family: co-headline,sans-serif;
			// 	font-weight: 400;
			// 	font-style: normal;
			// 	font-size: 4vw;
			// 	border:none;

			// 	@media screen and (min-width: 603px){
			// 		font-size: 2vw;
			// 	}
			// }

			// .react-add-to-calendar__button{
			// 	border-radius: 10px 10px 0px 0px;
			// 	border:none;
			// }
		}

		.MainInvite-contents::-webkit-scrollbar{
			display: none;
		}

		.locations{
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			font-size: 50px;

			.buttons {
				display: flex;
				flex-direction: row;
				justify-content: center;
				margin-top: 0.3rem;
				font-size: 1.1rem;
				margin-bottom: 2rem;

				.waze {
					display: flex;
					border:1px solid;
					border-radius: 0.5rem;
					padding: 0.1rem 1rem;
					margin-right: 0.5rem;
					align-items: center;
					cursor: pointer;
				}

				.maps {
					display: flex;
					border:1px solid;
					border-radius: 0.5rem;
					padding: 0.1rem 1rem;
					margin-left: 0.5rem;
					align-items: center;
					cursor: pointer;
				}
			}
		}
	}

	.calendar-container {
		margin-top: 10px;
		height: 30px;
		position: relative;


		.calendar-button {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			.calendar {
				display: block;
				position: absolute;
				z-index: 5;

				.react-add-to-calendar__button {

					color: white;
					font-size: 15px;
					border-radius: 20px;
					padding: 7px 15px 7px 15px;
					background-color: #05AFF2;
					border: 0;
				}

				.react-add-to-calendar__button:hover {
					text-decoration: none;
				}

				.react-add-to-calendar__dropdown ul {
					padding:0;
				}

				.action{
					font-family: grafolita-script, sans-serif;
					font-weight: 700;
					font-style: normal;
					font-size: 20px;
				}
			}
		}



	}



	.Answer{
		display: flex;
		flex-direction: column;
		padding-bottom: 40px;
		padding-top: 20px;
		padding-right: 15px;
		padding-left: 15px;
		background-color: #ffffff;

		.Options{
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			margin-top: 20px;

			@mixin option {
				width: 100%;
				color: white;
				font-size: 15px;
				font-weight: 600;
				border-radius: 20px;
				padding: 20px 5px;
				cursor: pointer;
				margin-bottom: 0.8rem;

			}


			.Yes{
				@include option;
				background-color: #00bb12;
			}

			.No{
				@include option;
				background-color: #ee2d2d;
			}

			.NotSure{
				@include option;
				border: 1px solid rgb(255, 123, 0);
				background-color: rgb(255, 123, 0)
			}
			.grey {
				opacity: 0.3 !important;
			}
		}
	}

	.Form{
		display: flex;
		flex-direction: column;
		padding: 30px 20px;
		background-color: #fff;

		.data-row{
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			margin: 10px 0px;

			@mixin option {
				width: 30%;
				padding: 5px;
				border-radius: 20px;
			}

			.active-option{
				@include option;
				background-color: #05AFF2;
				color: #FFFFFF
			}

			.inactive-option{
				@include option;
				background-color: #E5F7FE;
				color: #6B6996;
			}
		}

		.CustomQuestionCard{
			display: flex;
			flex-direction: column;
			margin: 20px 0px;
			overflow-y: scroll;

			.rs-checkbox-checker {
				width: 200px;
			}
		}
	}

	.add-button{
		border-radius: 80px;
		width: 60%;
		padding: 15px 0;
		margin: 10px auto;
	}
}

.modal-invitation{
	.account-options{
		display: flex;
		flex-direction: column;
		justify-content: space-around;

		button {
			min-width: 220px;
		}

		&.social-buttons {

			margin: 10px 0 20px 0;

			.social-login-container {
				display: flex;
				flex-direction: row;
			}

			.social-login-button {
				flex: 1;
				background-color: white;
				border: 1px solid rgba(34, 29, 93, 0.39);
				border-radius: 5px;
				padding: 8px 10px;
				cursor: pointer;
				font-size: 15px;
				color: #fff;
				width: 40%;
				margin:auto;
			}

			.social-login-button i {
				padding-right: 5px;
				font-size: 18px;
				position: relative;
				top: 2px;
			}

			.social-facebook-login {
				background-color: rgb(58, 89, 153);
			}

			.social-google-login {
				background-color: rgb(221, 76, 57);
			}

			.social-apple-login {
				background-color: rgb(243, 243, 243);
				color: #000;
			}
		}
	}

	.add-button{
		border-radius: 80px;
		width: 40%;
		margin: 10px auto;
	}
}

.Invitation::-webkit-scrollbar{
	display: none;
}

.Loading-Screen{
	display: flex;
	height: 100dvh;
	width: 90vw;
	margin: 0px auto;
	flex-direction: column;
	justify-content: center;
}

.react-add-to-calendar__dropdown ul li a {
	font-size: 18px;
	font-family: Apple-System, Arial, Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif;
}

@media only screen and (min-width: 800px) {
	.Invitation-outer {
		height: 100dvh;
		max-width: 800px;
		margin:auto;
		max-height: 100dvh;
		padding:30px;
		overflow-y: hidden;
	}
	.Invitation {
		display: flex;
		flex-direction: column;
		height: 100%;
		min-height: unset;
		overflow-y: auto;
		border-radius: 20px;
		border: 1px solid #f1f1f1;
	}

	.SaveTheDate-outer {
		height: 100dvh;
		max-width: 800px;
		margin:auto;
		max-height: 100dvh;
		padding:30px;
		overflow-y: hidden;
	}
	.SaveTheDate {
		display: flex;
		flex-direction: column;
		height: 100%;
		min-height: unset;
		overflow-y: auto;
		border-radius: 20px;
		border: 1px solid #f1f1f1;
	}
}
