.PaymentConfirm-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100dvh;
  width: 100vw;

  .PaymentConfirm-contents{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: start;
    overflow: scroll;
    height: 80dvh;
    width: 90vw;
    max-width: var(--container-max-width);
    margin: 0px auto;

  }

  .PaymentConfirm-contents::-webkit-scrollbar{
    display: none;
  }
}
