.AdminFeed-container{
    display:flex;
    flex-direction: column;
    justify-content: space-between;

    .AdminFeed-contents{
        display: flex;
        flex-direction: column;
		width: 90vw;
		max-width: var(--container-max-width);
		margin: auto;

		.AdminFeed-Feed {
			display: flex;
			height: 70dvh;
		}

		.AdminFeed-Input{
			display:flex;
			flex-direction: row;
			height: 100px;
			align-items: center;

			.rs-uploader-picture .rs-uploader-trigger-btn{
				margin: 0px !important;
				width: 40px;
				height: 40px;
			}
		}
    }
}