.Profile-container{
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
	width: 100vw;

	.Profile-TopText{
		display: flex;
		width: 100%;
		color: white;
		background: rgb(33,29,91);
		background: linear-gradient(42deg, rgba(33,29,91,1) 0%, rgba(38,91,196,1) 36%, rgba(0,146,168,1) 68%, rgba(0,149,214,1) 100%);
		align-items: center;
		justify-content: center;
	}

	.Profile-modal{
		display: flex;
        flex-direction: column;
		align-items: center;
        height: 10px;
	}

    .Profile-contents{
        //display: flex;
        //flex-direction: column;
		//align-items: center;
		align-self: center;
		height: calc(80dvh - 44px);
		width: 90vw;
		max-width: var(--container-max-width);
		overflow-y: scroll;

		.details-cont {
			text-align: left;
		}
		.details-item {
			width: 100%;
			display: inline-block;
		}

		.image-preview {
			width: 130px;
			height: 130px;
			border-radius: 150px;
			overflow: hidden;
			border: 1px solid #ccc;
			margin:1rem auto 0 auto;
		}

		.Section-Title{
			display: flex;
			align-content: flex-start;
			width: 100%;
		}

		.Section{
			margin-bottom: 20px;
			width: 100%;
			border-radius: 10px;
			background-color: #E5F7FE;
			padding: 1px 0;

			.Row{
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 5px;
				margin: 10px 0px;
				overflow-wrap: anywhere;

				.Data{
					display: flex;
					flex-direction: column;
					text-align: left;
				}

				.notification-type-row {
					flex-direction: row;

					.label {
						display: flex;
						align-items: center;
						padding-left: 10px;
					}
				}
			}
		}

    }

	.Profile-contents::-webkit-scrollbar{
		display: none;
	}
}

@media screen and (min-width: 550px){
	.Profile-container {
		.Profile-contents {
			.details-item {
				width: 50%;
			}
		}
	}
}
