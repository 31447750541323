.rs-uploader-trigger input[type='file']{
    display: none;
}

.EditInvite-container{
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    font-style: normal;

    .Footer{
		display: flex;
		justify-content: space-around;
        width: 100%;
        margin: 10px auto;

        .add-button{
            height: 60px;
            border-radius: 20px;
            width: 100%;
			margin-bottom: 10px;
            font-size: 20px;
            font-family: 'Signika', sans-serif;
            font-weight: 700;
            font-style: normal;
            margin-bottom: 20px;
        }

        #disclaimer{
            font-size:10px;
        }
    }

    .EditInvite-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 80dvh;

        .Upload-zone{
            margin-bottom: 50px;
            text-align: left;

            #text{
                padding: 50px 2px;
            }

            h3{
                font-weight: 800;

                @media screen and (max-width: 765px) {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

			.rs-uploader-text .rs-uploader-file-items{
				margin-top: 0px;
			}
        }

        .input-zone{
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: center;
			height: auto;
			overflow-y: scroll;
            width: 90vw;
			max-width: var(--container-max-width);
            margin: auto;

			.Invite-Design {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin: 10px 0px;

				.color-container{
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;

					.color-choice{
						display: flex;
						flex-direction: row;

						.styles_closeButton__20ID4{
							display: none;
						}
					}
				}
			}

            .InputGroup-Container{
                width: 100%;
                margin-bottom: 30px;
                border-bottom: solid #221d5d 1px;
        
            }

            .AddHours-date-picker{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-family: 'Signika', sans-serif;
                font-weight: 700;
                font-style: normal;            
            }

            .rs-picker-toggle .rs-picker-toggle-caret{
                display: none;
            }

            .rs-picker-toggle .rs-picker-toggle-placeholder{
                color: #221d5d;
                padding-left: 0px;
                font-weight: 700;
            }

            .AddJob-Icon{
                color: #221d5d;
            }

            #job-select::placeholder{
                padding-left:10px;
            }

            #job-select{
                background: transparent;
                color: #221d5d;
                border: none;
                border-radius: 0px;
                border-bottom: 1px solid #221d5d;
                width : 100%;
                display: flex;
                flex-direction: row;
                font-family: 'Signika', sans-serif;
                font-weight: 700;
                font-style: normal;
            }

            .AddJob-Container{
                margin-bottom: 30px;
                margin-top: 30px;

                .rs-picker-toggle-value{
                    padding-left: 25px;
                }

                .rs-picker-toggle .rs-picker-toggle-placeholder{
                    color: #221d5d;
                    padding-left:25px;
                }
            }
            
            #input{
                background: transparent;
                color: #221d5d;
                border: none;
                border-radius: 0px;
                border-bottom: 1px solid #221d5d;
            }

            #inputText{
                background: transparent;
                color: #221d5d;
                border: none;
                border-radius: 0px;
                border-bottom: 1px solid #221d5d;
                height: 13dvh;
                padding-left:35px;
            }

            #input::placeholder{
                color: #221d5d;
            }

            #inputText::placeholder{
                color: #221d5d;
            }
        }

		.input-zone::-webkit-scrollbar{
			display: none;
		}

        #title{
            color: #221d5d;
            font-size: 25px;
			margin: 10px auto;
            // text-align: left;
        }

        #sau{
            margin: 10px 0px;
        }

        .event-code{
            font-size: 17px;
            font-weight: 700;
        }
    }
}