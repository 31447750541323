.SeatingChart-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100dvh;
	width: 100vw;
	overflow: scroll;

    .SeatingChart-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 50dvh;
    }
}

.SeatingChart-container::-webkit-scrollbar{
	display: none;
}

.SeatingChart-Modal-Container{
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	height: 500px;
	width: 500px;

	.TableNumber{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		@media screen and (max-width: 700px) {
			margin-left: 80px;
		}
	}

	@mixin table {
		width: 200px;
		height: 200px;
		top: 50%;
		left: 50%;
		position: absolute;
		transform: translate(-50%, -50%);
		border: 2px solid grey;

		@media screen and (max-width: 700px) {
			margin-left: 80px;
		}
	}

	.RoundTable{
		@include table;
		border-radius: 50%;
	}

	.SquareTable{
		@include table;
	}

	.RectangleTable{
		width: 250px !important;
		height: 150px !important;
		@include table;
	}
}

.styles_modalCenter__L9F2w{
	overflow-x: scroll;
}

.styles_modalCenter__L9F2w::-webkit-scrollbar{
	display: none;
}

.std-modal{
    height: 75dvh;
    width: 85vw;
    border-radius: 10px;

    .modal-content{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        #code{
            margin-top: 5dvh;

            p{
                text-align: center;
                font-weight: 700;
                font-size: 40px;
            }
        }

        #invites{
            margin-top: 5dvh;

            p{
                font-size: 20px;
            }

            #icons{
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                height: 10dvh;

                #whatsapp{
                    width: 70px;
                    height: auto;
                }

                #messenger{
                    width: 60px;
                    height: auto;
                }
            }
        }
    }
}