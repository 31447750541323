.LineupTab-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 70dvh;
  margin: 0px auto;
  border-radius: 10px;
  background-color: white;
  text-align: left;

  .lineup {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    color: #1E1C59;
    border-bottom: 1px solid rgba(30, 28, 89, 0.36);
    padding-bottom: 10px;

    h3 {
      font-weight: 600;
      text-align: center;
    }

    h4 {
      margin-top: 10px;
      font-weight: 500;
      text-align: center;
    }
  }

  img {
    max-width: 100%;
  }

  .artist-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    &.set {
      color: #a40000;
    }

    .name { width: 70% }
    .date {
      width: 30%;
      text-align: right;
      i {
        margin-left: 5px;
      }

    }

  }

  .LineupTab-buttons{
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.LineupTab-GuestModal {
  .social-login-container {
    display: flex;
    flex-direction: row;
  }

  .social-login-button {
    flex: 1;
    background-color: white;
    border: 1px solid rgba(34, 29, 93, 0.39);
    border-radius: 5px;
    padding: 8px 10px;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
  }

  .social-login-button i {
    padding-right: 5px;
    font-size: 18px;
    position: relative;
    top: 2px;
  }

  .social-facebook-login {
    background-color: rgb(58, 89, 153);
  }

  .social-google-login {
    background-color: rgb(221, 76, 57);
  }

  .social-apple-login {
    background-color: rgb(243, 243, 243);
    color: #000;
  }
}