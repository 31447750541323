#activeBlock {
    background-color: #05AFF2;
	color: white;
}

.MenuBlock-container{
    display: flex;
    flex-direction: row;
    height: 70px;
    width: 100%;
	max-width: var(--container-max-width);
    border-radius: 80px;
    background-color: #E5F7FE;
    color: #1E1C59;
    margin: 20px auto;
    cursor: pointer;

    &.walkthrough-active {
        position: relative;
        z-index: 10000000;
        animation: zoominoutsinglefeatured 1.5s infinite;

        &.walkthrough-active-absolute {
            z-index: 1000000000;
            top: -400px;
        }
    }

    .walkthrough-arrow {
        position: absolute;
        z-index: 10000020;
        top: -60px;

        img {
            width: 60px;
            z-index: 10000020;
        }
    }

    .MenuBlock-content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        vertical-align: middle;
        padding: 15px;
        width: 100%;

        .left {
            border-radius: 20px;
            padding : 5px 10px;
            width: 15%;
        }

        .middle{
            text-align: center;
            align-self: center;
            width: 70%;

            #details{
                font-size: 12px;
                font-weight: 300;
            }

            h5 {
                font-size: 19px;
                font-weight: 500;
            }
        }

		.right{
			position: relative;
			width: 15%;
		}
    }
}
