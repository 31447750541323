.Invite-container{
    display: flex;
    flex-direction: column;
	align-items: center;
    height: 70dvh;
    margin: 0px auto;
    border-radius: 10px;
	text-align: left;

	.Invite-content{
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		height: 100%;
		width: 100%;
		border-radius: 10px;
    	background-color: rgba(255, 255, 255, 0.4);

		.ticket-validity{
			display: flex;
			flex-direction: row;
			justify-content: space-evenly;
			align-self: center;
			width: 140px;
		}

		.bottom-buttons .add-button {
			width: unset;
		}
		button {
			min-width: 200px;
		}
	}
}
