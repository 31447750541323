.ResponseTab-container{
    display: flex;
    flex-direction: column;
    height: 70dvh;
    margin: 0px auto;
    border-radius: 10px;
    background-color: white;
	text-align: left;
	overflow-y: scroll;

    .ResponseTab-buttons{
        display: flex;
		flex-direction: row;
		width: 90%;
        justify-content: space-between;
		margin-top: 10px;
    }

	.data-row{
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin: 10px 0px;
		text-align: center;

		@mixin option {
			width: 30%;
			padding: 5px;
			border-radius: 20px;
		}

		.active-option{
			@include option;
			background-color: #05AFF2;
			color: #FFFFFF
		}

		.inactive-option{
			@include option;
			background-color: #E5F7FE;
			color: #6B6996;
		}
	}

	.CustomQuestionCard{
		margin: 20px 0px;
	}

	.rs-picker-default .rs-picker-toggle{
		border: none;
		background-color: #E5F7FE !important;
	}
}

.ResponseTab-container::-webkit-scrollbar{
	display: none;
}
