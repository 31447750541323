.EventPage-container{
    height: 100dvh;
    display: flex;
    flex-direction: column;
    background-color: white;

	.EventPage-contents{
		display: flex;
		flex-direction: column;
		height: 80dvh;

		.PageContent{
			margin: 10px auto;
			border-radius: 10px;
			overflow-y: scroll;
			height: 100%;
			max-width: var(--container-max-width);
			width: 90vw;
		}

		.PageContent::-webkit-scrollbar{
			display: none;
		}
	}

	.side-bar{
		position: fixed;
		background: rgb(33, 29, 91);
		background: linear-gradient(42deg, rgba(33, 29, 91, 1) 0%, rgba(38, 91, 196, 1) 36%, rgba(0, 146, 168, 1) 68%, rgba(0, 149, 214, 1) 100%);

		display: flex;
		flex-direction: column;
		// justify-content: space-between;
		color: white;
		top: 10%;
		width: 70vw;
		height: 90dvh;
		z-index: 2000;
		overflow-y: scroll;
	}

	.side-bar::-webkit-scrollbar{
		display: none;
	}

	.side-bar-line{
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		margin-top: 8px;
		margin-bottom: 8px;

		@mixin sideBar {
			display: flex;
			align-items: center;
			width: 60%;
			height: 40px;
			margin-left: 10px;
			font-weight: bold;
		}

		.side-bar-item{
			@include sideBar;
		}

		.side-bar-item-active{
			@include sideBar;
			color: #1DE9B6;
		}

		.side-bar-dot{
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background-color: red;
		}
	}

	.side-bar-blur{
		position: fixed;
		background-color: #00000080;
		top: 10%;
		left: 70%;
		width: 30vw;
		height: 90dvh;
		z-index: 2000;
	}
}
