.HostessPage-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100dvh;
    color: #221d5d;

    .HostessPage-contents{
        display: flex;
        flex-direction: column;
        width: 90vw;
		max-width: var(--container-max-width);
		height: 80dvh;
        margin: auto;
		overflow: scroll;
    }
	::-webkit-scrollbar{
		display: none;
	}
}