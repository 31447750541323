.QrCheckerInvite-container{
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  width: 100vw;

  .QrCheckerInvite-TopText{
    display: flex;
    width: 100%;
    color: white;
    background: rgb(33,29,91);
    background: linear-gradient(42deg, rgba(33,29,91,1) 0%, rgba(38,91,196,1) 36%, rgba(0,146,168,1) 68%, rgba(0,149,214,1) 100%);
    align-items: center;
    justify-content: center;
  }

  .QrCheckerInvite-contents{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    height: calc(80dvh - 34px);
    width: 90vw;
    max-width: var(--container-max-width);
    overflow-y: scroll;
  }

  .QrCheckerInvite-contents::-webkit-scrollbar{
    display: none;
  }
}
