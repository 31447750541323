.MenuTab-contents{
	display: flex;
	flex-direction: column;
	height: 80dvh;
	margin: auto;
	border-radius: 10px;
	background-color: white;

	.MenuTab-MenuData{
		display: flex;
		flex-direction: column;
		height: auto;
		overflow-y: scroll;

		.Uploader{
			height: 100px;
			width: 90vw;
			margin: 20px auto;
			border: 1px solid #221d5d;
			border-radius: 5px;
		}
	}

	.MenuTab-MenuData::-webkit-scrollbar {
		display: none;
	}
}
