.rs-check-tree-node-expand-icon-wrapper{
    display: none
}

.rs-modal-dialog{
    width:90vw;
    margin: auto;
}

.modal-code-events{
    padding-top:5dvh;
    text-align: center;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}

.Modal-Container{
    text-align: left;
    width: 90%;

    #code-input{
        width: 80vw;
        margin: 10px 0px;
    }
}