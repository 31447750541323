.EditInviteComp {


}

.invite-section-edit-modal {
  margin-top: 20px;
  min-width: 250px;

  .input-title {
    padding: 0px 5px;
    font-size: 12px;
    color: black;
  }

  .avatar-cont {

    .image-preview {
      width: 230px;
      height: 230px;
      border-radius: 150px;
      overflow: hidden;
      border: 1px solid #000000;
      margin:auto;

      img {
       width: 100%;
      }

      &.corporate {
        background:#ccc;
        border-radius:0;
        border:0;
        width:330px;
        height:160px;
      }
    }
    .actions {
      .line {
        display: flex;
        flex-direction: row;
        .col-left {
          display: flex;
        }
        .col-right {
          display: flex;
          flex:1;
          padding: 0 10px 0 10px;

          input {
            width: 100%;
          }
        }
      }
    }
  }


    .location{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 20px 0px;

      @mixin line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        width: 100%;
        padding: 10px 20px;
        font-size: 15px;
        text-align: center;
      }

      .header{
        @include line;
        font-weight: bold;
        font-size: 15px;
        background-color: #05aff2;
      }

      .uneven-line{
        @include line;
        background-color: #e5f7fe;
      }

      .even-line{
        @include line;
        background-color: #d1e0ed;
      }
    }

    .location :first-child{
      border-radius: 20px 20px 0px 0px;
    }

    .location :last-child{
      border-radius: 0px 0px 20px 20px;
    }

}

@media screen and (min-width: 700px){
  .section-edit {
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }
}



