.InvitePage-container{
    height: 100dvh;
    background-size:cover;

    .InvitePage-contents{
        min-height:99.9dvh;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;

		.action{
			height: 60px;
		}

        #names {
            font-family: grafolita-script, sans-serif;
            font-weight: 700;
            font-style: normal;
            margin-bottom: 2dvh;
            // font-size: 10vw;

            @media screen and (min-width: 603px){
                font-size: 5vw;
                margin-bottom:5dvh;
            }
        }

        #saveTheDate{
            font-family: co-headline,sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 4vw;

            @media screen and (min-width: 603px){
                font-size: 2vw;
                margin-bottom:1dvh;
            }
        }

        #date{
            font-family: co-headline,sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 4vw;
            margin-bottom:2dvh;

            @media screen and (min-width: 603px){
                font-size: 2vw;
            }
        }

        /*
        .text{
            font-weight: 400;
            font-style: normal;
            // font-size: 15px;
            width:80%;
            margin:0px auto;
			overflow-wrap: break-word;
            font-size: 1.3rem;


            @media screen and (min-width: 603px){
                font-size: 1.4rem;
            }
        }
         */

		.question{
			height: 100px;
			width: 80%;
			max-width: 500px;
			text-align: center;
			margin: 20px auto;

			.rs-btn{
				color: white;
			}
		}

        .calendar{
            margin-top: 1dvh;
        }

        .react-add-to-calendar__dropdown{
            font-family: co-headline,sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 4vw;
            border-radius: 0px 0px 10px 10px;
            width: 100%;
            left: 0px;
            border:none;

            @media screen and (min-width: 603px){
                font-size: 1.3rem;
                margin-top: 2dvh;
            }

            li:nth-child(-n + 2){
                margin-bottom: 2dvh;
            }
        }

        .react-add-to-calendar__wrapper{
            font-family: co-headline,sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 4vw;
            border:none;

            @media screen and (min-width: 603px){

                font-size: 2vw;
            }
        }

        .react-add-to-calendar__button{
            border-radius: 10px 10px 0px 0px;
            border:none;
        }
    }
}

.new-invite-based-container {
    display: flex;
    flex-direction: column;
    background-image: url("/images/bg-invite.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100dvh;



    .row1 {
        display: flex;
        max-height: 60dvh;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }

    .row2 {
        display: flex;
        flex:1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1rem 0.5rem 1rem;
        color: #000000;
        font-size: 0.9rem;
        background: #dfe9eb;
    }

    .Answer{
        display: flex;
        flex:1;
        flex-direction: column;
        background-color: #ffffff;

        .ask-title {
            color: #000000;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .Options{
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-around;
            padding-bottom: 1rem;

            @mixin option {
                width: 100%;
                color: white;
                font-size: 15px;
                font-weight: 600;
                border-radius: 20px;
                padding: 20px 5px;
                cursor: pointer;
                text-align: center;
                margin: 0 10px;
            }

            .Yes{
                @include option;
                background-color: #3bbe06;
            }

            .No{
                @include option;
                background-color: red;
            }

            .NotSure{
                @include option;
                background-color: rgb(255, 123, 0);
            }
        }
    }

    .Form{
        display: flex;
        flex-direction: column;
        padding: 30px 20px;
        background-color: #fff;

        .data-row{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin: 10px 0px;

            @mixin option {
                width: 30%;
                padding: 5px;
                border-radius: 20px;
            }

            .active-option{
                @include option;
                background-color: #05AFF2;
                color: #FFFFFF
            }

            .inactive-option{
                @include option;
                background-color: #E5F7FE;
                color: #6B6996;
            }
        }

        .CustomQuestionCard{
            display: flex;
            flex-direction: column;
            margin: 20px 0px;
            overflow-y: scroll;

            .rs-checkbox-checker {
                width: 200px;
            }
        }
    }

    .invite-based-container {
        width: 100%;
        max-width: 500px;
        margin: 0 auto 0 auto;
        overflow: auto;
    }

    .btn-back {
        margin-bottom: 4rem;
    }
}

@media screen and (min-width:500px) {
    .new-invite-based-container {
        .invite-based-container {
            border-radius: 10px;
            border: 1px solid #ccc;
            margin: 1rem auto 1rem auto;
            max-width: 500px;
            min-width: 500px;

            .row2 {
                font-size: 0.9rem;
            }

            .InvitePage-container {
                height: unset !important;

                .InvitePage-contents {
                    min-height: unset;
                }
            }
        }
    }
}
