.ConfirmEmailChangePage-container{
    display: flex;
	justify-content: space-between;
    flex-direction: column;
    min-height: 100dvh;
	width: 100vw;

    .ConfirmEmailChangePage-contents{
        display: flex;
        flex-direction: column;
        // justify-content: space-around;
		align-items: center;
        height: 77dvh;
		max-width: 500;
    }
	
	.style-width{
		max-width: 500px;
		width: 70vw;
		align-self: center;
	}
}