.AdModal-Container{
	font-family: "sans-serif";
	text-align: "center";
}

.modal-overlay.z3000 {
	z-index: 3000;
}

.modal-align-selft-center {
	align-items: center;
}

.modal.size{
	width: 100vw;
	height: 90dvh;
	text-align: center;
}