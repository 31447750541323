.play-audio-custom {
  width: 100%;
  justify-content: center;
  display: flex;

  .play-audio-button {
    display: flex;
    justify-content: center;
    cursor: pointer;


    .icon-container {
      margin-right: 12px;
      width: 3rem;
      height: 3rem;
    }

    .text-container {
      font-size: 20px;
      display: flex;
      text-align: center;
      vertical-align: center;
      align-items: center;
    }
  }
}
