.event-category-selector {
  display: flex;
  flex-direction: row;
  margin: 0.5rem auto 0.5rem auto;
  background-color: #e8e7ed;
  border-radius: 10px;
  overflow: hidden;
  max-width: 300px;
  width: 100%;

  .event-category-item {
    flex:1;
    border: 1px solid #e8e7ed;
    border-radius: 10px;
    font-size: 0.85rem;
    padding: 6px;
    cursor: pointer;
    text-align: center;


    &.active {
      background-color: #06aff2;
      color: #fff;
    }
  }


}
