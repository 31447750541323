.EventData-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
	color: white;
	height: 100%;
    margin: 0px auto;
    border-radius: 10px;

    .EventData-contents{
        background-color: rgba(0, 0, 0, 0.4);
        color: white;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 3rem 25px;
		overflow-y: scroll;

        .EventData-intro{
            margin-bottom: 5dvh;

			.text{
				font-family: co-headline,sans-serif;
				font-weight: 400;
				font-style: normal;
				font-size: 15px;
				max-height: 300px;
				margin:0px 0px;
				overflow-wrap: break-word;
				overflow-y: scroll;
			}
        }

        .EventData-location{
            margin: 4dvh 0px;

            .EventData-navbutton{
                display: flex;
                flex-direction: row;
				justify-content: center;
                margin-top:5px;

                #navButton{
                    margin-right: 15px;
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }
    }
}