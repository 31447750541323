.IntermediatePageEvents-container{
    font-family: Signika, sans-serif;
    font-weight: 500;
    font-style: normal;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100dvh;
	color: #1E1C59;

	.Title{
		display: flex;
		align-items: center;
		height: 10dvh;
		font-weight: bold;
		font-size: 30px;
	}

    .IntermediatePageEvents-contents{
        display: flex;
        flex-direction: column;
        width: 90vw;
		height: 70dvh;
		overflow-y: scroll;
		margin: auto;
		justify-content: center;
    }

	.IntermediatePageEvents-contents::-webkit-scrollbar{
		display: none;
	}
}