.AlbumsTab-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    margin: 0px auto;
    border-radius: 10px;

    .AlbumsTab-contents-image{
        width:90%;
        margin: auto;

        #details{
            text-align: left;
            margin: 3dvh 0px;
        }
    }

	.AlbumsTab-content{
		margin-top: 10px;

		.link{
			display: flex;
			justify-content: center;
			align-items: center;
			height: 50px;
			border-radius: 20px;
			margin-top: 20px;
			font-weight: 500;
			color: #05AFF2;
			background-color: #E5F7FE;
		}
	}
}