.Events-container{
    display: flex;
    flex-direction: column;
	justify-content: space-between;
    height: 100dvh;
	width: 100vw;

	.Events-TopText{
		position: relative;
		display: flex;
		width: 100%;
		color: white;
		background: rgb(33,29,91);
		background: linear-gradient(42deg, rgba(33,29,91,1) 0%, rgba(38,91,196,1) 36%, rgba(0,146,168,1) 68%, rgba(0,149,214,1) 100%);
		align-items: center;
		justify-content: center;

		.search-box {
			top:0;
			position: absolute;
			right: 10px;
			font-size: 12px;

			.search-icon {
				font-size: 12px;
			}
		}


		.icon-left{
			position: absolute;
			left: 15px;
		}

		.search-bar{
			background-color: rgba(255, 255, 255, 0);
			color: white;
			border: none;
			margin: 0px 30px;
		}
	}

	.Events-Filter{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 43px;
		max-height: 43px;
		min-height: 43px;
		max-width: var(--container-max-width);
        width: 90vw;
		margin: 5px auto;
		background-color: rgb(232, 231, 237);
		border-radius: 10px;
		overflow-x: scroll;

		@mixin css($color: white) {
			border-radius: 10px;
			font-size: 11px;
			padding: 6px 13px;
			color: $color;
			flex:1;
			height: 100%;
			justify-content: center;
			align-items: center;
			display: flex;
		}

		.Events-Status-Option{
			@include css($color: grey);
			font-size: 15px;
			white-space: nowrap;
		}

		.Events-Status-Choice{
			@include css();
			font-size: 15px;
			background-color: rgb(6, 175, 242);
			white-space: nowrap;
		}

		.Events-Filter-Option{
			@include css($color: grey);
			white-space: nowrap;
		}

		.Events-Filter-Choice{
			@include css();
			background-color: rgb(6, 175, 242);
			white-space: nowrap;
		}
	}

	.Events-Filter::-webkit-scrollbar{
		display: none;
	}

	.Events-Filter2{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 29px;
		max-height: 29px;
		min-height: 29px;
		max-width: var(--container-max-width);
		width: 90vw;
		margin: 5px auto;
		background-color: rgb(232, 231, 237);
		border-radius: 10px;
		overflow-x: scroll;

		@mixin css($color: white) {
			border-radius: 10px;
			font-size: 11px;
			padding: 6px 13px;
			color: $color;
			flex:1;
			height: 100%;
			justify-content: center;
			align-items: center;
			display: flex;
		}

		.Events-Status-Option{
			@include css($color: grey);
			font-size: 15px;
			white-space: nowrap;
		}

		.Events-Status-Choice{
			@include css();
			font-size: 15px;
			background-color: rgb(6, 175, 242);
			white-space: nowrap;
		}

		.Events-Filter-Option{
			@include css($color: grey);
			white-space: nowrap;
		}

		.Events-Filter-Choice{
			@include css();
			background-color: rgb(6, 175, 242);
			white-space: nowrap;
		}
	}

	.Events-Filter2::-webkit-scrollbar{
		display: none;
	}

    .Events-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
		overflow-y: scroll;
        height: calc(80dvh - 90px);

		.Events-placeholder {
			margin-top: 8rem;
			padding: 0 15px;
			text-align: center;
		}

		.Events-Card-Container{
			display: flex;
			flex-direction: column;
			min-height: 70px;
			max-width: var(--container-max-width);
			width: 90vw;
			margin: 10px auto;
			border-radius: 10px;
			background-color: #E8E7EE;
			color: #1E1C59;

			.Picture{
				min-height: 150px;
				width: 100%;
				border-radius: 10px 10px 0px 0px;

				img {
					width: 100%;
				}
			}

			.Open-Event{
				display: flex;
				justify-content: center;
				background-color: #05AFF2;
				color: white;
			}

			.Title{
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				text-align: left;
				font-size: 20px;
				margin-left: 5px;
				margin-right: 5px;
				margin-top: 10px;

				.FavoriteIcon{
					color: grey;
				}

				.FavoriteIcon-Filled{
					color: red;
				}
			}

			.Icons{
				display: flex;
				flex-direction: row;
				align-items: center;
				width: 100%;
				overflow: scroll;

				@mixin event{
					display: flex;
					flex-direction: row;
					justify-content: space-around;
					align-items: center;
					color: white;
					min-width: 80px;
					height: 22px;
					font-size: 11px;
					border-radius: 5px;
					margin: 5px;
					padding: 1px 3px;
				}

				.Data{
					@include event;
					background-color: #05AFF2;
					padding: 2px 10px;
					white-space: nowrap;
				}

				.New{
					@include event;
					background-color: red;
					padding: 2px 10px;
				}

				.PublicEvent{
					@include event;
					background-color: #2879F6;
				}

				.PrivateEvent{
					@include event;
					background-color: #70163C;
				}

				.Delete{
					@include event;
					border: 1px solid #F26157;
					color: #F26157;
				}

				.Share{
					@include event;
					background-color: #19B899;
				}
			}

			.Description{
				text-align: left;
				font-size: 14px;
				margin: 5px;
				max-width: 90%;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}

			.Full-Description{
				text-align: left;
				margin: 5px;
				font-size: 14px;
				white-space: pre-line;
				text-overflow: ellipsis;
				word-break: break-word;
			}

			.Icons::-webkit-scrollbar{
				display: none;
			}
		}

		.Events-Card-Container :first-child{
			border-radius: 10px 10px 0px 0px;
		}
    }

	.Events-contents::-webkit-scrollbar{
		display: none;
	}
}

@media screen and (max-width: 380px) {
	.Events-TopText {
		font-size: 1rem;
	}
}
