.invites-selection-cont {
  display: flex;
  flex-direction: row;
  max-width: 700px;
  margin: auto;
  flex-wrap: wrap;
  justify-content: center;

  .invite-item {
    background: #E5F7FE;
    color: #1E1C59;
    padding: 10px 10px;
    margin: 0 5px 5px 5px;
    border-radius: 20px;
    height: 40px;
    font-size: 15px;

    &.active {
      background: #19B899;
      color: #ffffff;
    }
  }
}
