.send-invite-modal{

  height: 77dvh;
  width: 85vw;
  border-radius: 10px;
  margin-top: -1px;

  .modal-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .send-invite-modal-inner {
      padding-top:1rem;
      display: block;

      textarea {
        height: 5rem;
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
      }

      .share-title {
        text-align: center;
        margin-top: 1rem;
      }

      .share-butons-cont {
        display: flex;
        flex-direction: column;

        .share-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin-top: 1.5rem;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }

          .icon {
            img {
              width: 3rem;
            }
          }

          .text {
            padding-left: 1rem;
            font-size: 1rem;
          }
        }
      }
    }


    #code{
      margin-top: 5dvh;

      p{
        text-align: center;
        font-weight: 700;
        font-size: 40px;
      }
    }

    #invites{
      margin-top: 5dvh;

      p{
        font-size: 20px;
      }

      #icons{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        #whatsapp{
          width: 70px;
        }
        #messenger{
          width: 60px;
        }
      }

    }

  }
}

@media screen and (max-width: 500px) {
  .send-invite-modal {
    height: unset;
    width: 85vw;

    .modal-content {

      .send-invite-modal-inner {

        textarea {
          height: 8rem;
        }
      }
    }
  }
}
