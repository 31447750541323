.NavbarColor-container {
  //background: rgb(33,29,91);
  //background: linear-gradient(42deg, rgba(33,29,91,1) 0%, rgba(38,91,196,1) 35%, rgba(0,146,168,1) 68%);
  color: white;
  height: 10dvh;
  width: 100vw;
  z-index: 1000;
  font-family: Signika, sans-serif;
  font-weight: 500;
  font-style: normal;

  .NavbarColor-contents {
    box-shadow: -1px -12px 30px 5px rgba(0, 0, 0, 0.25);
    border-radius: 40px 40px 0px 0px;
    background: rgb(33, 29, 91);
    background: linear-gradient(42deg, rgba(33, 29, 91, 1) 0%, rgba(38, 91, 196, 1) 36%, rgba(0, 146, 168, 1) 68%, rgba(0, 149, 214, 1) 100%);
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10dvh;
    justify-content: space-between;
    overflow: hidden;
    max-width: 900px;
    margin: auto;
    font-size:11px;

    .menu-element, .active-element {
      .icon-row {
       flex:1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .text-row {
        flex:1;
        width: 75px;
        margin:auto;
        padding-top:3px;
      }
    }

    .menu-element {
      display: flex;
      flex: 1;
      flex-direction: column;
      //width: 75px;
      padding: 10px;
    }

    .active-element {
      display: flex;
      flex: 1;
      flex-direction: column;
      //width: 75px;
      //color: #05AFF2;
      //background-color: rgba(255, 255, 255, 0.1);
      background: rgba(33, 29, 91, 0.5);
      padding: 10px;
    }
  }
}
@media screen and (max-width: 900px) {

  .NavbarColor-container {
    .NavbarColor-contents {
      font-size: 9px;

      .menu-element, .active-element {
        width: 75px;

        .text-row {
          width: unset;
          margin: unset;
        }
      }
    }
  }
}
