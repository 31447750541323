.FeedBox-container{
	display: flex;
	width: 100%;
	flex-direction: column;
	overflow-y: scroll;

	.FeedBox-Post{
		display: block;
		margin-top: 10px;
		text-align: left;

		.FeedBox-ChatBubble-Cont {
			text-align: left;
		}

		.FeedBox-ChatBubble{
			display: inline-block;
			max-width: 80%;
			flex-direction: column;
			padding: 10px;
			border-radius: 24px;
			background-color: rgba(25, 187, 87, 0.356);
		}
	}

	.FeedBox-Post-User{
		display: block;
		margin-top: 10px;
		text-align: right;

		.FeedBox-ChatBubble-Cont {
			text-align: right;
		}

		.FeedBox-ChatBubble-User{
			display: inline-block;
			max-width: 80%;
			flex-direction: column;
			padding: 10px;
			border-radius: 24px;
			background-color: greenyellow;
			text-align: left;
		}
	}
}

.FeedBox-container::-webkit-scrollbar {
		display: none;
}

.poza{
	display: flex;
	width: 30dvh;
	height: 30dvh;
}
