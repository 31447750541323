.ResetName-container{
    display: flex;
    flex-direction: column;
    min-height: 90dvh;
	width: 90vw;
    margin: 0px auto;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
	text-align: left;

    .ResetName-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
		align-items: center;
        min-height: 50dvh;
		max-width: 400;
    }
}