.ParticipantsTab-container{
  display: flex;
  flex-direction: column;
  height: 70dvh;
  width: 100%;
  margin: 0px auto;
  border-radius: 10px;
  text-align: left;
  overflow-y: scroll;

  .user-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #dadada;
    padding: 0.5rem 0.5rem;

    &.clickable {
      cursor: pointer;
      &:hover {
        background: #f1f1f1;
      }
    }

    .image {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      overflow: hidden;
      margin-right: 10px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    .name {
      flex:1;
      font-size: 16px;
    }

  }
}

.ParticipantsTab-container::-webkit-scrollbar{
  display: none;
}

.participantstab-modal {
  min-width: 250px;
  margin-bottom: 10rem;

  .image {
    width: 140px;
    height: 140px;
    border-radius: 70px;
    overflow: hidden;
    margin: auto;

    img {
      width: 140px;
      height: 140px;
    }
  }

  .Row {
    margin-top: 15px;
  }

  .social {
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    span {
      flex:1;
      text-align: center;
    }
  }

  .rs-btn {
    margin-top: 15px;
  }
}

@media screen and (min-width: 700px) {
  .participantstab-modal {

    .user-rows {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 600px;
      margin:auto;
      overflow-wrap: anywhere;
    }
    .Row {
      width: 50%;
    }
  }
}