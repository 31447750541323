.SetParticipantsTables-container{
	display: flex;
	flex-direction: column;
	height: 100dvh;
	width: 100vw;
    color: #221d5d;

	.SetParticipantsTables-contents{
		display: flex;
		flex-direction: column;
		// justify-content: space-between;
		height: 80dvh;
		width: 90vw;
		margin: 0 auto;
		overflow: scroll;
	}

	.SetParticipantsTables-contents::-webkit-scrollbar{
		display: none;
	}

	.NoTablePlan{
		height: 90dvh;

		.Details{
			margin: 50px auto;
		}
	}
}

.SetParticipantsTables-Modal-Container{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 70dvh;
	width: 100%;

	.Modal-Content{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: auto;
		width: 90%;
		overflow-y: scroll;
	}

	.Modal-Content::-webkit-scrollbar{
		display: none;
	}
}
