.AdminSeatingChartModal-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #221d5d;

  .AdminSeatingChartModal-contents{
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    //height: 80dvh;
    //width: 90vw;
    //max-width: var(--container-max-width);
    //margin: 0 auto;
    //overflow: scroll;
  }

  .AdminSeatingChartModal::-webkit-scrollbar{
    display: none;
  }

  .NoTablePlan{
    height: 90dvh;

    .Details{
      margin: 50px auto;
    }
  }
}

.AdminSeatingChartModal-Modal-Container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70dvh;
  width: 100%;

  .Modal-Content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    width: 90%;
    overflow-y: scroll;
  }

  .Modal-Content::-webkit-scrollbar{
    display: none;
  }
}