.Partners-container{
    display: flex;
    flex-direction: column;
    min-height: 70dvh;
    margin: 0px auto;
    border-radius: 10px;
    background-color: white;
	text-align: left;

	.Partners-logo{
		align-self: center;
		min-height: 30dvh;
		width: 30dvh;
		margin-bottom: 20px;
	}

    .Partners-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
		align-items: center;
        min-height: 50dvh;
		max-width: 400;
    }
}