.ThankYouRegisterPage {

  .GeneralScreen-contents {
    display: flex;
  }

  .icon {
    img {
      max-width: 100px;
    }
  }
  .title {
    margin-top: 2rem;
    line-height: 2.5rem;
  }
  .subtitle {
    font-size: 1.2rem;
    margin-top: 1rem;
    color: #777;
  }
  .button-cont {
    text-align: center;
  }
}