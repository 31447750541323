.add-audio-row{
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
  width: 100%;
  margin: 12px 0;

  .add-audio-col {
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;

    .add-audio-card {
      max-width: 100%;
      cursor: pointer;
      border-radius: 15px;
      background-color: #E5F7FE;
      color: #6B6996;
      padding: 10px 10px;
      &.active{
        background-color: #05AFF2;
        color: #FFFFFF;
      }
    }
}
}
.play-audio-button-container{
  .play-audio-button{
    background-color: transparent;
    padding: 0;
    vertical-align: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    .icon-container{
      height: 2em;
      width: calc(2em + 12px);
    }
    svg{
      margin-right: 12px;
      width: 2em;
      height: 2em;
    }
  }
}
.songs-container{
  max-height: 300px;
  overflow-y: auto;
  font-size: 16px;
  text-align: left;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.song-container{
  display: flex;
  justify-content: center;
  width: inherit;
  margin-right: 6px;
  //vertical-align: center;
  align-items: center;
  padding: 10px 8px;

  .play-audio-button-container{
    flex: 1;
  }

  .choose-song{
    float: right;
    margin-left: auto;
    button{
      border-radius: 8px;
    }
  }
  &.song-selected{
    border-radius: 8px;
    background-color: rgba(143,188,143,0.2);

  }
}