.WeddingParticipantsPhotos-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100dvh;
  width: 100vw;

  .WeddingParticipantsPhotos-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    height: 80dvh;
    width: 90vw;
    max-width: var(--container-max-width);
    margin: 0px auto;
    text-align: center;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.WeddingParticipantsPhotosUpload-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100dvh;
  width: 100vw;

  .WeddingParticipantsPhotosUpload-contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex:1;
    overflow: scroll;
    width: 90vw;
    max-width: var(--container-max-width);
    margin: 0px auto;
    text-align: center;
    padding: 2rem 0;

    &::-webkit-scrollbar {
      display: none;
    }


    .uploader {
      width: 100%;

      .rs-uploader-file-items {
        display: flex;
        flex-direction: column-reverse;
      }

      .rs-uploader-trigger-btn {
        height: 2.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 1rem;

        i {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

