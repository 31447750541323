.PdfViewer-container{
    display: flex;
    flex-direction: column;
	align-items: center;
	width: 90vw;
	max-width: var(--container-max-width);
    margin: 0px auto;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
	text-align: left;

	.zoom{
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin: 10px 0px;

		@mixin default {
			background-color: #E8E7EE;
			font-size: 25px;
			padding: 5px 30px 0px 30px;
		}

		.left{
			@include default;
			border-radius: 20px 0px 0px 20px;
		}
		
		.right{
			@include default;
			border-radius: 0px 20px 20px 0px;
		}
	}

    .PdfViewer-buttons{
        display: flex;
		flex-direction: row;
		width: 100%;
        justify-content: space-around;
    }
}
