.Exports-container{
    display: flex;
    flex-direction: column;
    height: 100dvh;
    background-color: white;
	justify-content: space-between;
	align-items: center;
	font-family: 'Signika', sans-serif;
	font-weight: 500;
	font-style: normal;

	.export-invite-cont {
		will-change: transform;
		-webkit-transform: translateZ(0);
		-moz-transform: translateZ(0);
		-ms-transform: translateZ(0);
		-o-transform: translateZ(0);
		transform: translateZ(0);

		.section1 {
			max-width: unset !important;
		}
	}

    .Exports-contents{
        display: block;
        flex-direction: column;
        width: 90vw;
		height: 80dvh;
        margin: auto;
		overflow-y: scroll;

		.Title{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 10dvh;
			color: #1E1C59;
			font-weight: bold;
			font-size: 30px;
		}

		.Download-Line{
			display: flex;
			flex-direction: column;
			width: 100%;
			margin: 20px auto;
			overflow-x: auto;
			max-width: 100%;

			input, button, select, textarea{
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				height: 40px;
				width: 75%;
				max-width: 300px;
				margin: 10px auto;
				border-radius: 80px;
				background-color: #E5F7FE;
				color: #1E1C59;
			}
		}
    }
}

@media screen and (min-width: 991px) {
	.export-invite-cont {
		align-self: center;
	}
}