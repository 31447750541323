.ResultsPage-container{
    display: flex;
	height: 100dvh;
    width : 100vw;

    .ResultsPage-content{

        height: 100dvh;
        width : 100vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .Login{
            display: flex;
            flex-direction: column;
            height: 90dvh;
            width: 90vw;
            margin: auto;
            justify-content: space-between;
        }

        .Login-one{
            height: 10dvh;
            width: 100vw;
        }
    }
}
