.ParintiComponent-container{

    .ParintiComponent-content{


        .Display {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 10px;
            .nas-element{
            
                 font-size: 20px;
            
            }
        }
        
    }
}