.AddSaveTheDate-container{
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-weight: 700;

    .Footer{
		display: flex;
		justify-content: space-around;
        width: 100%;
        margin: 20px auto;

        .add-button{
            height:6dvh;
            border-radius: 20px;
            width: 100%;
			margin-bottom: 10px;
            font-size: 20px;
            font-family: 'Signika', sans-serif;
            font-weight: 700;
            font-style: normal;
        }

        #disclaimer{
            font-size:10px;
        }
    }

    .AddSaveTheDate-contents{
        display: flex;
        flex-direction: column;
        height: 80dvh;

        .rs-input{
            color: #221d5d;
        }

        .rs-input::placeholder{
            color: #221d5d;
        }

        .Upload-zone{
            margin-bottom: 10px;
            text-align: center;

            h3{
                font-weight: 800;

                @media screen and (max-width: 765px) {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

			.rs-uploader-text .rs-uploader-file-items{
				margin-top: 0px;
			}
        }

		.Upload-Audio{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.Buttons{
				display: flex;
				flex-direction: row;
				justify-content: center;
				margin: 10px 0px;
			}
		}

        .input-zone{
            display:flex;
            flex-direction: column;
            text-align: center;
			overflow-y: scroll;
            width: 90vw;
			max-width: var(--container-max-width);
            margin: auto;
            color: #221d5d;

			.ChangeChoiceButton{
				width: 70%;
				text-align: center;
				background-color: #E3B505;
				margin:10px auto 30px auto;
				padding: 5px;
				border-radius: 20px;
				color: white;
			}

			.Section{
				margin-top: 20px;

				.Title{
					text-align: center;
					font-size: 18px;
				}

				.Options-Row{
					display: flex;
					flex-direction: row;
					justify-content: center;
					margin: 10px 0px;
		
					@mixin option {
						width: 50px;
						padding: 10px;
						border-radius: 20px;
					}
		
					.active-option{
						@include option;
						background-color: #05AFF2;
						color: #FFFFFF
					}
		
					.inactive-option{
						@include option;
						background-color: #E5F7FE;
						color: #6B6996;
					}
				}
			}

			.Input_Type_Options{
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				flex-wrap: wrap;
				overflow-y: scroll;

				.Input_Type_Option{
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					font-size: 15px;
					color: #221d5d;
                  margin-bottom: 20px;

					.Circle{
						display: flex;
						justify-content: center;
						align-items: center;
						height: 100px;
						width: 100px;
						margin: 10px 50px;
						border-radius: 50%;
						background-color: #E5F7FE;
					}
				}
			}

			.Input_Type_Options::-webkit-scrollbar{
				display: none;
			}

			.Invite-Design {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin: 10px 0px;

				.color-container{
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;

					.color-choice{
						display: flex;
						flex-direction: row;

						.styles_closeButton__20ID4{
							display: none;
						}
					}
				}
			}

            .InputGroup-Container{
                width: 300;
                width: 100%;
                margin-bottom: 30px;
                border-bottom: solid #221d5d 1px;
            }

            .AddHours-date-picker{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-family: 'Signika', sans-serif;
                font-weight: 700;
                font-style: normal;            
            }

            .rs-picker-toggle .rs-picker-toggle-caret{
                display: none;
            }

            .rs-picker-toggle .rs-picker-toggle-placeholder{
                color: #221d5d;
                padding-left: 0px;
                font-weight: 700;
            }

            .AddJob-Icon{
                color: #221d5d;
            }

            #job-select::placeholder{
                padding-left:10px;
            }

            #job-select{
                background: transparent;
                color: #221d5d;
                border: none;
                border-radius: 0px;
                border-bottom: 1px solid #221d5d;
                width : 100%;
                display: flex;
                flex-direction: row;
                font-family: 'Signika', sans-serif;
                font-weight: 700;
                font-style: normal;
            }

            .AddJob-Container{
                margin-bottom: 30px;

                .rs-picker-toggle-value{
                    padding-left: 25px;
                }

                .rs-picker-toggle .rs-picker-toggle-placeholder{
                    color: #221d5d;
                    padding-left:25px;
                }
            }

            #input{
                background: transparent;
                color: #221d5d;
                border: none;
                border-radius: 0px;
                border-bottom: 1px solid #221d5d;
            }

            #inputText{
                background: transparent;
                color: #221d5d;
                border: none;
                border-radius: 0px;
                border-bottom: 1px solid #221d5d;
                height: 13dvh;
                padding-left:35px;
            }

            #input::placeholder{
                color: #221d5d;
            }

            #inputText::placeholder{
                color: #221d5d;
            }
        }
		.input-zone::-webkit-scrollbar{
			display: none;
		}

        #title{
            color: #221d5d;
            font-size: 25px;
        }

        #sau{
            margin: 10px 0px;
        }

        .event-code{
            font-size: 17px;
            font-weight: 700;
        }

        h4{
            width:80%;
            margin: 2dvh auto;
            color: #221d5d;
        }
    }
}