.BalVotes-container{
	display: flex;
	flex-direction: column;
	height: 70dvh;
	margin: 0px auto;
	border-radius: 10px;
	background-color: white;
	text-align: left;

	.BalVotes-contents{
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow-y: scroll;

		.BalVotes-candidates{
			display: flex;
			flex-direction: row;
			height: 100px;
			width: 90%;

			.Picture{
				height: 100px;
				width: 100px;
			}

			.Text{
				width: 70%;
				text-overflow: ellipsis;
				overflow: hidden; 
				white-space: nowrap; 
			}
		}
	}

	.BalVotes-contents::-webkit-scrollbar{
		display: none;
	}
}

.modal-overlay.votes {
	z-index: 3000;
}

.modal.votesSize{
	width: 400px;
	height: 400px;
	text-align: center;
}