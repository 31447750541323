.Announcements-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0px auto;
    border-radius: 10px;

    .announcement{
		border: 1px solid #1E1C594D;
		margin: 10px 0px;
		padding: 10px;
		border-radius: 10px;

		.content img{
			max-width: 100%;
		}
    }
}