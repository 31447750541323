.HeaderWhite-container{
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 1px 1px 0px 0px rgba(0,0,0,0.15);
    color: #221d5d;
    width: 100%;
    font-family: Signika, sans-serif;
    font-weight: 700;
    font-style: normal;

    .HeaderWhite-contents{
        display: flex;
        flex-direction: row;
        justify-content: center;
		align-items: center;
   		height: 10dvh;
        max-width: 700px;
        align-self: center;

        .walkthrough-over-icon {
            animation: zoominoutsinglefeatured 1.5s infinite;
        }

        .walkthrough-over-arrow {
            position: absolute;
            left: 80px;
            top: 55px;
            animation: zoominoutsinglefeatured 1.5s infinite;

            img {
                transform: rotate(180deg);
                width: 80px;
            }
        }

        .notifications-cont {
            position: absolute;
            margin-top: 8px;
            right: 90px;
            cursor: pointer;

            .notifications-cont-inner {
                position: relative;
                width: 30px;
                background: #ffffff;

                .notification-unread-count {
                    position: absolute;
                    top: -8px;
                    right: -4px;

                    background-color: red;
                    color: #ffffff;
                    padding: 1px 3px;
                    border-radius: 5px;
                    font-size: 0.8rem;
                }
            }
        }


		.with-back{
            display: flex;
            justify-content: space-between;

            i{
                font-size: 35px;
                align-self: center;
            }
        }

        h3{
            text-align: center;
        }
    }
}
