.AdminEventPage-container{
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-family: 'Signika', sans-serif;
    font-weight: 700;
    font-style: normal;
  overflow: hidden;

	.AdminEventPage-contents{
		display: flex;
		height: 79dvh;
	}

	.AdminEventPage-contents::-webkit-scrollbar{
		display: none;
	}
}