.EventPageP-container{
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;

	.EventPage-contents{
		display: flex;
		flex-direction: column;
		width: 90vw;
		max-width: var(--container-max-width);
		margin: 0px auto;
		height: calc(70dvh - 30px);

		.PageContent{
			display: flex;
			flex-direction: column;
			margin: 10px 0px 0px 0px;
			border-radius: 10px;
			overflow-y: scroll;
			height: 100%;
		}

		.PageContent::-webkit-scrollbar{
			display: none;
		}
	}
}