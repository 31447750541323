
.walkthrough-overlay {
  position: absolute;
  width: 100%;
  height: 100dvh;
  z-index: 100000;
  background-color: rgba(0,0,0,0.85);
  overflow: hidden;
  overflow-y: auto;

  .content {
    display: flex;
    flex:1;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #ffffff;
    padding: 0 20px;

    p {
      font-size: 1.3rem;
      font-weight: 400;
      margin-bottom: 2rem;
      max-width: 900px;
    }

    .image-cont {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      gap: 10px;
      opacity: 0.9;
      width: 100%;
      justify-content: center;
      height: 120px;

      img {
        height: 120px;
        border-radius: 5px;

      }
    }

    .video-cont {
      max-height: 300px !important;
      max-width: unset;
      margin: unset;
    }
  }

  .next-button {
    color: #ffffff;
    background-color: #00afcc;
    border-radius: 0.25rem;
    padding: 5px 15px;
    cursor: pointer;
    font-size: 1.4rem;
    animation: zoominoutsinglefeatured 1.5s infinite;
  }

  .skip-button {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #000000;
    background-color: #ffffff;
    border-radius: 0.25rem;
    padding: 5px 15px;
    opacity: 0.8;
    cursor: pointer;
  }
}

.walkthrough-overlay-back {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background-color: rgba(0, 0, 0, 0.25);
}

.walkthrough-overlay-back-invite {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .tip-text {
    font-size: 0.9rem;
    background-color: #05AFF2;
    color: #fff;
    opacity: 0;
    padding: 5px 5px;
    border-radius: 3px;
    animation: opacityinoutsingle 3s infinite;
    position: absolute;
    bottom: calc(8dvh + 90px);
  }
}

@media screen and (max-height: 800px) {
  .walkthrough-overlay .content {
    .video-cont {
      max-height: 220px !important;
      max-width: unset;
      margin: unset;
    }
  }
}