.ViewEvents-container{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100dvh;

	.title{
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: relative;
		width: 100%;
		color: white;
		background: rgb(33,29,91);
		background: linear-gradient(42deg, rgba(33,29,91,1) 0%, rgba(38,91,196,1) 36%, rgba(0,146,168,1) 68%, rgba(0,149,214,1) 100%);

		.search-box {
			top:0;
			position: absolute;
			right: 10px;
			font-size: 12px;

			.search-icon {
				font-size: 12px;
			}
		}

		.icon-left{
			position: absolute;
			left: 15px;
		}

		.search-bar{
			background-color: rgba(255, 255, 255, 0);
			color: white;
			border: none;
			margin: 0px 30px;
		}
	}

	.filters{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 45px;
		width: 90vw;
		max-width: var(--container-max-width);
		background-color: rgb(232, 231, 237);
		border-radius: 10px;
		margin: 5px auto;
		// overflow: scroll;

		@mixin css($color: white) {
			border-radius: 10px;
			font-size: 12px;
			padding: 6px;
			color: $color
		}

		.Events-Status-Option{
			@include css($color: grey);
			width: 50%;
		}

		.Events-Status-Choice{
			@include css();
			width: 50%;
			background-color: rgb(6, 175, 242);
		}
	}

	.filters2{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		height: 45px;
		width: 90vw;
		max-width: var(--container-max-width);
		background-color: rgb(232, 231, 237);
		border-radius: 10px;
		margin: 5px auto;
		// overflow: scroll;
		overflow-x: auto;

		@mixin css($color: white) {
			border-radius: 10px;
			font-size: 12px;
			padding: 6px;
			color: $color
		}

		.Events-Status-Option {
			@include css($color: grey);
			padding: 6px 13px;
			white-space: nowrap;
			flex:1;
		}

		.Events-Status-Choice{
			@include css();
			padding: 6px 13px;
			background-color: rgb(6, 175, 242);
			white-space: nowrap;
			flex:1;
		}

	}

	.filters::-webkit-scrollbar, .filters2::-webkit-scrollbar{
		display: none;
	}

    .ViewEvents-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
		width: 90vw;
		max-width: var(--container-max-width);
		margin: 0px auto;
		overflow-y: scroll;

    }

	.ViewEvents-contents::-webkit-scrollbar{
		display: none;
	}
}

@media screen and (max-width: 380px) {
	.ViewEvents-container {
		.title {
			font-size: 1rem;
		}
	}
}
