.EditProfile-container{
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  width: 100vw;

  .EditProfile-TopText{
    display: flex;
    width: 100%;
    color: white;
    background: rgb(33,29,91);
    background: linear-gradient(42deg, rgba(33,29,91,1) 0%, rgba(38,91,196,1) 36%, rgba(0,146,168,1) 68%, rgba(0,149,214,1) 100%);
    align-items: center;
    justify-content: center;
  }

  .EditProfile-contents{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    height: calc(80dvh - 34px);
    width: 90vw;
    max-width: var(--container-max-width);
    overflow-y: scroll;
    padding-bottom: 5rem;
    overflow-x: hidden;

    .item-row {
      display: flex;
      flex-direction: row;
      width: 100%;

      .item-col {
        flex:1;

        &.last {
          display: flex;
          flex:0 !important;
          align-items: flex-end;
          justify-content: flex-end;

          .rs-btn-toggle {
            width: 60px;
          }
        }

      }
    }

    .avatar-title {
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      text-align: left;
    }

    .avatar-cont {
      margin-bottom: 1rem;


      input {
        width: 100%;
      }

      .image-preview {
        width: 180px;
        height: 180px;
        border-radius: 90px;
        overflow: hidden;
        border: 1px solid #ccc;
        margin-bottom: 0.5rem;
      }
      .actions {
        .line {
          display: flex;
          flex-direction: row;
          .col-left {
            display: flex;
          }
          .col-right {
            display: flex;
            flex:1;
            padding: 0 10px 0 10px;

            input {
              width: 100%;
            }
          }
        }
      }
    }

    .CustomInput-Basic {
      width: 100%;
    }


  }

  .EditProfile-contents::-webkit-scrollbar{
    display: none;
  }
}
