.EventNews-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
	width: 90vw;
	max-width: 500px;
    margin: 0px auto;
    border-radius: 10px;
    padding: 20px;
    background-color: white;

    .EventNews-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 50dvh;
        
    }
}