.MemoryBoothTab-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  height: 70dvh;
  margin: 0px auto;
  border-radius: 10px;


  .MemoryBoothTab-Feed {
    display: flex;
    flex-direction: column;
    height: calc(70dvh - 50px);

    .item {
      padding: 15px 0 15px 0;
      margin: 10px 0;
      border-bottom: 1px solid #f1f1f1;

      .head {
        padding-bottom: 5px;
        .sender, .recipient {
          color: #1E1C59;
          font-weight: 600;
        }
      }

      .preview {
        .row {
          display: flex;
          flex-direction: row;

          .left {
            flex:1;
            overflow: hidden;
          }

          .right {
            padding: 0 10px 0 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            .btn-download {
              border-radius: 5px;
              background-color: #1E1C59;
              width: 50px;
              height: 50px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              color: #ffffff;
              text-align: center;
            }
          }
        }
      }

      img {
        width: 100%;
        height: auto;
      }

    }
  }

}
