.InfoPage-container{
    display: flex;
    flex-direction: column;
	align-items: center;
    min-height: 70dvh;
	width: 90vw;
	max-width: 500px;
    margin: 0px auto;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
	text-align: left;

    .InfoPage-buttons{
        display: flex;
		flex-direction: row;
		width: 90%;
        justify-content: space-between;
		margin-top: 10px;
    }
}