@mixin CustomInput{
	display: flex;
	flex-direction: row;
	position: relative;
	padding: 5px;
	color: #221d5d;
	border-radius: 10px;
	background-color: #E5F7FE;

	.CustomInput-TopText{
		position: absolute;
		top: -15px;
		padding: 0px 5px;
		font-size: 12px;
		color: black;
		background-color: #ffffff00;
	}
}

.CustomInput-Basic{
	@include CustomInput;
}

.CustomInput-Underline{
	@include CustomInput;
	border-bottom: 1px solid #221d5d;
}

.CustomInput-Border{
	@include CustomInput;
	border: 1px solid #221d5d;
	border-radius: 5px;
}