.GeneralTab-container{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
	height: 100%;
	margin: auto;
	background-color: #E8E7EE;

    .Image{
		border-radius: 10px 10px 0px 0px;
		width: 100%;

		img {
			width:100%;
		}
	}

	.Data{
		display: flex;
		justify-content: center;
		background-color: #05AFF2;
		color: white;
	}

	.Title{
		margin: 10px 5px;
		font-size: 17px;
		font-weight: bold;
		color: #1E1C59;
	}

	@mixin desc{
		text-align: center;
		font-size: 13px;
		margin: 5px 20px;
		color: #1E1C59;
		white-space: pre-line;
		word-break: break-word;
	}

	.Description{
		@include desc;
	}

	.Full-Description{
		@include desc;
		font-size: 15px;
	}

	.Location{
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 10px 0px;

		.Type{
			font-size: 15px;
			font-weight: bold;
			color: #05AFF2;
		}

		.Address{
			margin: 5px 0px;
			font-size: 13px;
			color: #1E1C59;
		}

		.Data-Line{
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin: 10px;

			.Data{
				align-self: center;
				font-size: 13px;
				width: 100px;
				background-color: #A5A5A5;
				border-radius: 10px;
				margin: 0px 10px;
			}
		}
	}

	.Buy-Ticket{
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 320px;
		max-width: 95%;
		margin: 10px auto;
		padding: 10px 10px;
		border-radius: 10px;
		align-items: center;
		color: white;
		background-color: #70163C;
	}
}

.GeneralTab-container::-webkit-scrollbar{
	display: none;
}