.InviteHeader-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.15);
  color: #221d5d;
  width: 100%;
  font-family: Signika, sans-serif;
  font-weight: 700;
  font-style: normal;


  .InviteHeader-contents {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 10dvh;
    align-self: center;
    width: 100%;


    .with-back {
      display: flex;
      align-items: center;
      width: 100%;
      flex-direction: row;


      .left-col {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .title-col {
        display: none;
        text-align: center;
      }

      .right-col {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        padding-right: 10px;

        .btn-save {
          display: flex;
          flex-direction: row;
          background-color: #05AFF2;
          border-radius: 20px;
          padding: 10px 25px 10px 15px;
          overflow: hidden;

          .icon-cont {
            width: 15px;
            margin-right: 10px;
          }
          img {
            width: 13px;

            animation-name: icon-beat;
            animation-delay: 5s;
            animation-direction: normal;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
          }
        }

        .btn-preview {
          background-color: transparent;
          border: 1px solid #1E1C59;
          color: #1E1C59;
          border-radius: 20px;
          padding: 10px 20px;

          margin-right: 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 750px) {
  .InviteHeader-container {
    .InviteHeader-contents {
      .with-back {
        .title-col {
          display: block;
        }
        .left-col {
          flex:1;
        }
      }
    }
  }
}

@keyframes icon-beat {
  0%, 90% {
    transform: scale(1);
  }
  45% {
    transform: scale(1.3);
  }
}