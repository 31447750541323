.CheckPresence-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100dvh;
    color: #221d5d;

    .CheckPresence-contents{
        display: flex;
        flex-direction: column;
        width: 90vw;
		max-width: var(--container-max-width);
		height: 80dvh;
        margin: auto;
		overflow: scroll;

		.Attendance{
			background-color: #E3B505;
			color: white;
			font-size: 16px;
			margin: 10px auto;
			padding: 10px 20px;
			border-radius: 20px;
		}

		.participants-limit {
			.participants-limit-title {
				display: inline-block;
				margin-right: 10px;
			}

			.select-participants-limit-alert {
				display: inline-block;
				padding: 6px 20px;
				border: 1px solid #ccc;
				border-radius: 20px;
				font-size: 14px;
			}
		}
    }
	::-webkit-scrollbar{
		display: none;
	}
}