.DataTable-line-container{
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100px;
	border-bottom: 1px solid black;

	.DataTable-line {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}

.DataTable-headers{
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-left: 5vw;
	margin-right: 5vw;
	border-bottom: 2px solid white;
}