.GuestUnsubscribe-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100dvh;
  width: 100vw;

  .GuestUnsubscribe-contents{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90vw;
    max-width: var(--container-max-width);
    height: 80dvh;
    margin: auto;
    padding: 10px 0px;
    overflow-y: auto;

    .questions-form {
      width: 100%;
      padding: 0 15px 0 15px;
    }

  }
}
