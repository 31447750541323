.HostessPage-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100dvh;
    color: #221d5d;

    .HostessPage-contents{
        display: flex;
        flex-direction: column;
        width: 90vw;
		height: 80dvh;
        margin: auto;

      .present_participants_header {
        width: 100%;
        z-index: 1000;
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: #000000;
        background-color: transparent;
        margin-top: 20px;
      }

		.present_participants{
            position: relative;
			display: flex;
			flex-direction: row;
			justify-content: center;
			border: 1px solid black;
			border-radius: 20px;
            overflow: hidden;

          .progress-bar {
            color: white;
            height: 20px;
            box-shadow: 1px 1px 0 0 rgba(0,0,0,0.15);
            width:100%;
            background-color: transparent;

            .inner {
              background: rgb(33,29,91);
              background: linear-gradient(42deg, rgba(33,29,91,1) 0%, rgba(38,91,196,1) 36%, rgba(0,146,168,1) 68%, rgba(0,149,214,1) 100%);
              height: 100%;
              transition-duration: 1s;
            }
          }
		}
    }
}