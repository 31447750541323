.AlbumsTab-wedding-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    width: 90vw;
    max-width: var(--container-max-width);
    margin: 0px auto;
    border-radius: 10px;
    background-color: white;

    .AlbumsTab-album-content{
		display: flex;
		flex-direction: column;
		width: 90vw;
		max-width: var(--container-max-width);
		background-color: #e8e7ee;
		color: #1e1c59;
		border-radius: 10px;
		margin: 20px auto;
		text-align: left;
	
		.Picture{
			height: 150px;
			width: 100%;
		}
	
		.Edit-Gift{
			display: flex;
			justify-content: center;
			background-color: #05AFF2;
			color: white;
		}
	
		.Title{
			font-size: 20px;
			margin-left: 5px;
			margin-top: 10px;
		}
	
		.Description{
			text-align: left;
			margin: 5px;
			font-size: 14px;
			white-space: pre-line;
			text-overflow: ellipsis;
			word-break: break-word;
		}
	}
	
	.EventCard-container-Album :first-child{
		border-radius: 10px 10px 0px 0px;
	}
}