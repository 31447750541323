.ShowTable-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100dvh;

    .ShowTable-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 50dvh;
    }
}

.ShowTable-Modal-Container{
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	height: 600px;
	width: 600px;

	.TableNumber{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	@mixin table {
		width: 200px;
		height: 200px;
		top: 50%;
		left: 50%;
		position: absolute;
		transform: translate(-50%, -50%);
		border: 2px solid grey;
	}

	.RoundTable{
		@include table;
		border-radius: 50%;
	}

	.SquareTable{
		@include table;
	}

	.RectangleTable{
		width: 250px !important;
		height: 150px !important;
		@include table;
	}

	.ShowTable-Modal-Content{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		margin: 30px 0px;
	}

}

.showtable-zoom-cont {
	position: absolute;
	bottom: 10px;
	right: 10px;
	z-index: 100;

	.item.zoom {
		padding: 5px;
		color: #1E1C59;
	}
}