.Notifications-Screen-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100dvh;
  width: 100vw;

  .Notifications-Screen-contents {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 80dvh;
    margin: auto;
    padding: 10px 0px;
    overflow-y: auto;

    &::-webkit-scrollbar{
      display: none;
    }

    .notifications-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 0 10px;



      .notification-item {
        display: flex;
        flex-direction: row;
        text-align: start;

        padding: 1rem 0 1rem 0;
        width: 100%;
        max-width: 700px;
        background-color: #E8E7EE;
        color: #1E1C59;
        border-radius: 8px;
        margin-bottom: 1rem;


        &.unseen, &:hover {
          background-color: #05AFF2;
          color: #ffffff;
        }

        &.action {
          cursor: pointer;
        }

        .left-col {
          width: 50px;
          min-width: 50px;
          text-align: center;
        }

        .right-col {
          padding: 0 8px 0 0;

          .title {
            font-size: 1rem;
            font-weight: 600;
          }
          .message {
            font-size: 0.9rem;
          }
          .date {
            margin-top: 0.3rem;
            font-size: 0.8rem;
            font-weight: 300;
          }

        }
      }
    }
  }

}
