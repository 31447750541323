.PreviewSaveTheDate-container{
	min-height: 90dvh;
	width: 100%;
	background-size: cover;
	margin: 0px auto;

	.PreviewSaveTheDate-contents{
		min-height: 70dvh;
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		color: white;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		word-break: break-word;

		.description{
			height: 50%;
			overflow-y: scroll;
		}

		.description::-webkit-scrollbar{
			display: none;
		}
	}
}
.Answer{
	display: flex;
	flex-direction: column;
	//margin-bottom: 10px;

	.Options{
		display: flex;
		flex-direction: row;
		justify-content: space-around;

		@mixin option {
			width: 31%;
			color: white;
			font-size: 15px;
			border-radius: 20px;
			padding: 5px;
		}

		.Yes{
			@include option;
			background-color: #05AFF2;
		}

		.No{
			@include option;
			background-color: red;
		}

		.NotSure{
			@include option;
			background-color: rgb(255, 123, 0);
		}
	}
}