.MenuAdmin-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  height: 100%;
  width: 90vw;
  margin: 0px auto;
  padding: 1px;
  border-radius: 10px;

  .countdown {
    display: flex;
    flex-direction: row;
    align-self: center;
    flex: 1;
    max-width: 700px;
    min-height: 85px;

    .item {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 0.5rem;

      .value {
        display: flex;
        border: 1px solid #1E1C59;
        border-radius: 0.5rem;
        justify-content: center;
        font-size: 1.2rem;
        padding: 0.5rem;
        color: #1E1C59;
      }

      .title {
        padding-top: 0.2rem;
        font-size: 0.8rem;
      }
    }
  }

  .Title {
    font-size: 30px;
    color: #1E1C59;
  }

  .Date {
    align-self: center;
    width: 100px;
    color: white;
    background-color: #19B899;
    margin: 10px 0px;
    border-radius: 5px;
  }

  .Name {
    font-size: 20px;
    color: #05AFF2;
  }

  .active-package {
    display: inline-block;

    margin-bottom: 10px;

    &.free {
      //background: #19b899;
      color: #19b899;
    }

    &.invites {
      //background: #19b899;
      color: #19b899;
    }

    &.premium {
      //background: #E8630A;
      color: #E8630A;
    }

  }

  .MenuAdmin-contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-top: 10px;
    overflow-y: scroll;

    .AddHours-date-picker {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .rs-picker-toggle .rs-picker-toggle-caret {
      display: none;
    }

    .rs-picker-toggle .rs-picker-toggle-placeholder {
      color: #221d5d;
      padding-left: 0px;
      font-weight: 700;
    }

    .InputGroup-Container {
      width: 300;
      width: 100%;
      margin-bottom: 30px;
      border-bottom: solid #221d5d 2px;
    }

    .InputGroup-Login::placeholder {
      color: #221d5d;
    }

    .InputGroup-Login {
      color: #221d5d;
      border: none;
      font-size: 15px;

      input::placeholder {
        color: #221d5d;
      }
    }

    .Continue-button {
      background-color: #00B7AD;
      width: 100%;
      height: 48px;
      color: white;
      font-weight: 900;
      border-radius: 15px;
      font-size: 20px;
      cursor: pointer;
    }

    .basic-info {
      display: flex;
      flex-direction: column;
    }

    .MenuAdmin-intro {
      margin-bottom: 5dvh;
    }

    .MenuAdmin-location {
      text-align: left;
      margin: 4dvh 0px;

      .MenuAdmin-navbutton {
        display: flex;
        flex-direction: row;
        margin-top: 5px;

        #navButton {
          margin-right: 15px;
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
  }

  .MenuAdmin-contents::-webkit-scrollbar {
    display: none;
  }
}
