.Export-SaveTheDate-container{
    height: 100%;
	width: 100%;
    background-size: cover;
	margin: 0px auto;
	overflow-y: hidden;

    .Export-SaveTheDate-contents{
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
		word-break: break-word;

		.description{
			overflow-y: scroll;
		}

		.description::-webkit-scrollbar{
			display: none;
		}
    }
}