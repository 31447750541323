.share-comp-cont {
  .share-button {
    background-color: #4caf50;
    color: white;
    border-radius: 40px;
    padding:8px 20px;
    font-size: 14px;
    font-weight: normal;
    margin:10px;
  }

}

.share-comp-modal{

  height: 77dvh;
  width: 85vw;
  border-radius: 10px;
  margin-top: -1px;

      #icons{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex:1;
        height: 100%;

        #whatsapp{
          width: 70px;
          margin-right: 30px;
        }
        #messenger{
          width: 60px;
        }
      }


}
