.bottom-buttons{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 90%;
	margin: 10px auto;

	.add-button{
		border-radius: 80px;
		width: 40%;
	}

	.back-button{
		width: 40%;
		border: 1px solid #1e2c59;
		border-radius: 80px;
	}
}

.helper-modal{
	height: 400px;
	width: 90vw;

	.modal-content{
		display: flex;
		flex-direction: column;
		justify-content: center;
		overflow-y: scroll;

		.icons{
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			height: 100px;
		}
	}

	.modal-content::-webkit-scrollbar{
		display: none;
	}
}

.helper-invite-modal{

	width: 90vw;

	.modal-content{
		display: flex;
		flex-direction: column;
		justify-content: center;
		overflow-y: scroll;

		.icons{
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			height: 100px;
		}
	}

	.modal-content::-webkit-scrollbar{
		display: none;
	}
}

.youtube-video iframe {
	@media screen and (max-width: 800px){
		width: 80vw;
	}
}

.data-table{
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
	margin-bottom: 20px;
	height: 100%;
	overflow: scroll;

	@mixin line {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		text-align: left;
		width: 100%;
		padding: 10px 20px;
		font-size: 15px
	}

	.header{
		@include line;
		font-weight: bold;
		font-size: 15px;
		background-color: #05aff2;

		.clickable-elem{
			display: flex;
			justify-content: flex-end;
			align-items: center
		}
	}

	.uneven-line{
		@include line;
		background-color: #e5f7fe;
	}

	.even-line{
		@include line;
		background-color: #d1e0ed;
	}
}

.data-table::-webkit-scrollbar{
	display: none;
}

.data-table :last-child{
	border-radius: 0px 0px 20px 20px;
}

.data-table :first-child{
	border-radius: 20px 20px 0px 0px;
}
