.DiplomaTab-container{
    display: flex;
    flex-direction: column;
    height: 70dvh;
    margin: 0px auto;
    border-radius: 10px;
    background-color: white;

	.DiplomaTab-contents{
		display: flex;
		flex-direction: column;
		height: auto;
		overflow-y: scroll;

		.DiplomaTab-questions{
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			text-align: left;
		}
	}

	.DiplomaTab-contents::-webkit-scrollbar{
		display: none;
	}
}
