.GiftMoney-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100dvh;
    color: #221d5d;

    .GiftMoney-contents{
        display: flex;
        flex-direction: column;
        width: 90vw;
		max-width: var(--container-max-width);
		height: 80dvh;
        margin: auto;

		.tabel{
			height: 100%;
			overflow: scroll;
		}

		.tabel::-webkit-scrollbar{
			display: none;
		}

		.tableLinesContainer{
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: auto;
			overflow-y: scroll;
			margin-bottom: 10px;
			background-color: white;
			color: black;

			.line-container{
				display: flex;
				flex-direction: column;
				justify-content: center;
				min-height: 50px;
				width: 100%;
				border-bottom: 2px solid black;
			
				.line {
					align-items: center;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
				}
			}
		}

		.tableLinesContainer::-webkit-scrollbar{
			display: none;
		}
    }
}