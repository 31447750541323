.Hostess-container{
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-family: 'Signika', sans-serif;
  font-weight: 700;
  font-style: normal;

  .Hostess-contents{
    display: flex;
    flex-direction: column;
    height: 79dvh;
    width: 90vw;
    max-width: var(--container-max-width);
    margin: 0px auto;

    .data-table {
      width: 100%;
    }

    .btn-remove-access {
      color: red;
      padding: 5px 10px 5px 10px;
      cursor: pointer;
    }
  }

  .Hostess-contents::-webkit-scrollbar{
    display: none;
  }

}
