.SeatingChart-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100dvh;
	width: 100vw;
	overflow: scroll;

    .SeatingChart-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 50dvh;
    }

}

.SeatingChart-container::-webkit-scrollbar{
	display: none;
}

.SeatingChart-Page{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100vw;
	height: 100dvh;
}

.SeatingChart-container-organizer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
	width: 100%;
	overflow: hidden;
	overscroll-behavior: none;

	.drawing-area {
		overflow-y:auto;
		margin: 0 auto;

		&::-webkit-scrollbar{
			//display: none;
		}

		canvas {
			border:2px solid #000000 !important;
		}
	}

	.side-list-cont {
		width: 100%;
		background-color: #E5F7FE;
		padding: 0 30px;
		overflow-y: auto;
	}

    .SeatingChart-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 50dvh;
    }

	.Icons-Bar{
		display: flex;
		flex-direction: column;
		height: 100%;
		min-width: 60px;
		max-width: 60px;
		background-color: #E5F7FE;

		.Home-Icon{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 60px;
			background-color: #1E1C59;
			color: white;
		}

		.Icons{
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 100%;
			color: #1E1C59;
			font-size: 11px;

			.text-item {
				margin-top: 5px;
				color: #1E1C59;
				font-size: 11px;
			}

			.item {
				flex:1;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				&.first {
					border-top: 1px solid #1E1C59;
				}

				&.save {
					color: #05aff2;
				}

				&.zoom {
					flex-grow: 0;
					padding: 5px 0 5px 0;
				}
			}

			.item.active, .item:active {
				background-color: #1E1C59;
				color: #ffffff;
			}

			.item.save.active, .item.save:active {
				background: #05aff2 !important;
				color: #ffffff  !important;
			}
		}
	}

	.Options{
		display: flex;
		position: absolute;
		left: 60px;
		z-index: 3;
		flex-direction: column;
		height: 100%;
		width: 200px;
		background-color: #E8E7EE;
		color: #1E1C59;

		.addElem {
			width: 200px;
			text-align: center;
			padding: 10px 0px;

			&:active {
				background-color: #4caf50;
				color: #ffffff;
			}
		}



		.Options-Title{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 56px;
			border-bottom: 1px solid grey;
		}
	}
}

.SeatingChart-container-organizer::-webkit-scrollbar{
	display: none;
}

.SeatingChart-Modal-Container{
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-width: 250px;

	.TableNumber{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		@media screen and (max-width: 700px) {
			margin-left: 80px;
		}
	}

	@mixin table {
		width: 200px;
		height: 200px;
		top: 50%;
		left: 50%;
		position: absolute;
		transform: translate(-50%, -50%);
		border: 2px solid grey;

		@media screen and (max-width: 700px) {
			margin-left: 80px;
		}
	}

	.RoundTable{
		@include table;
		border-radius: 50%;
	}

	.SquareTable{
		@include table;
	}

	.RectangleTable{
		width: 250px !important;
		height: 150px !important;
		@include table;
	}

	.SeatingChart-Modal-Content{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		margin: 30px 0px;

		.Wall-Dimensions{
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			margin: 10px 0px;

			.Len{
				display: flex;
				flex-direction: column;
				width: 40%;
				margin-bottom: 10px;
			}

			.Types{
				display: flex;
				flex-direction: row;
				width: 50%;
				justify-content: space-around;
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}

		.Wall-Example-Container{
			display: flex;
			height: 100%;
			min-height: 150px;
			width: 100%;
			justify-content: center;
			align-items: center;
			overflow-y: hidden;
			border: 1px solid black;
		}

		.Wall-Example-Container::-webkit-scrollbar{
			display: none;
		}
	}

	.SeatingChart-Modal-Content::-webkit-scrollbar{
		display: none;
	}
}

.SeatingChart-Modal-Container-mobile {
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.SeatingChart-Modal-Content{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		margin: 30px 0px;

		.Wall-Dimensions{
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			margin: 10px 0px;

			.Len{
				display: flex;
				flex-direction: column;
				width: 100%;
				margin-bottom: 10px;
			}

			.Types{
				display: flex;
				flex-direction: row;
				width: 100%;
				justify-content: space-around;
				margin-top: 10px;
				margin-bottom: 10px;
			}
		}

		.Wall-Example-Container{
			display: flex;
			height: 100px;
			width: 100%;
			justify-content: center;
			align-items: center;
			overflow-y: hidden;
			border: 1px solid black;
		}

		.Wall-Example-Container::-webkit-scrollbar{
			display: none;
		}
	}

	.SeatingChart-Modal-Content::-webkit-scrollbar{
		display: none;
	}
}

.styles_modalCenter__L9F2w{
	overflow-x: scroll;
}

.styles_modalCenter__L9F2w::-webkit-scrollbar{
	display: none;
}

@media only screen and (min-width: 700px) {
	.SeatingChart-Modal-Container {
		min-height: 700px;
	}

	.minHeightContainer {
		height: 500px;
	}
}

@media only screen and (min-width: 1200px) {
	.SeatingChart-container-organizer {
		.side-list-cont {
			min-width: 700px;
		}
	}
}


@media only screen and (max-width: 1200px) {
	.SeatingChart-container-organizer {
		.drawing-area.hide-on-mobile {
			display: none;
		}
	}
}



