.ViewAlbums-container{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100dvh;

    .ViewAlbums-contents{
        display: flex;
        flex-direction: column;
        height: 80dvh;
		width: 90vw;
		max-width: var(--container-max-width);
		margin: 0px auto;

        #title{
            color: #221d5d;
            font-size: 30px;
            // text-align: left;
            width: 80%;
            margin: 5px auto;
            font-family: 'Signika', sans-serif;
            font-weight: 700;
            font-style: normal;
        }

        .add-button-a{
			width: 100%;
			display: flex;
			font-weight: bold;
			font-size: 15px;
			margin: 10px auto;
        }

		.Status{
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			margin-bottom: 5px;

			.Details{
				font-weight: 600;
				margin-right: 20px;
			}

			@mixin default {
				padding: 5px;
				border-radius: 20px;
				color: white;
			}

			.Published{
				@include default;
				background-color: #05AFF2;
			}

			.Unpublished{
				@include default;
				background-color: red;
			}
		}

		.ViewAlbums-cards{
			height: auto;
			overflow-y: scroll;
		}

		.ViewAlbums-cards::-webkit-scrollbar{
			display: none;
		}
    }
}