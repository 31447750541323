.UploadExcelResponses-container{
  display: flex;
  flex-direction: column;
  height: 100dvh;
  color: #221d5d;

  .UploadExcelResponses-contents{
    display: flex;
    flex-direction: column;
    width: 90vw;
    max-width: var(--container-max-width);
    height: 90dvh;
    margin: auto;
    overflow-y: scroll;
  }

  .UploadExcelResponses-contents::-webkit-scrollbar {
    display: none;
  }
}
