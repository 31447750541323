.rs-check-tree-node-expand-icon-wrapper{

    display: none

}

.rs-modal-dialog{

    width:90vw;
    margin: auto;
}

.modal-loading{
    padding-top:5dvh;
    height:20dvh;
    border-radius: 10px;

    text-align: center;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-colors{
    padding-top: 50px;
    height: 230px;
    border-radius: 10px;

    text-align: center;
    display:flex;
    flex-direction: column;
    justify-content: space-between;

	.color-options{
		display: flex;
		flex-direction: column;

		.basic-color{
			display: flex;
			flex-direction: row;

			@mixin size($len: 30px) {
				height: $len;
				width: $len;
				border-radius: 50%;
				margin-top: 15px;
			}

			.basic-color-white{
				@include size;
				background-color: white;
				border: 1px solid black;
				margin-right: 10px;
			}

			.basic-color-black{
				@include size;
				background-color: black;
			}
		}
	}
}

.Modal-Container{

    text-align: left;
    width: 90%;

    p{
        
    }
    
    #code-input{
        width: 80vw;
        margin: 10px 0px;
    }
 
}