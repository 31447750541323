.AdminInvite-container{
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-family: 'Signika', sans-serif;
  font-weight: 700;
  font-style: normal;

  .AdminInvite-contents{
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    height: 79dvh;
    width: 90vw;
    max-width: var(--container-max-width);
    margin: 0px auto;
  }

  .AdminInvite-contents::-webkit-scrollbar{
    display: none;
  }

}