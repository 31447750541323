.AddResponse-container{
    display: flex;
    flex-direction: column;
    height: 100dvh;
    color: #221d5d;

    .AddResponse-contents{
        display: flex;
        flex-direction: column;
        width: 90vw;
		max-width: var(--container-max-width);
		height: 90dvh;
        margin: auto;
		overflow-y: scroll;
    }

	.AddResponse-contents::-webkit-scrollbar {
		display: none;
	}
}

.modal{
	margin-top: 10dvh !important;
	overflow-y: scroll !important;
}

.modal-no-top {
  overflow-y: scroll !important;
  margin-bottom: 10dvh !important;
}

.rs-check-tree-node{
	text-align: left;
}