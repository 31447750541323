$base-color: rgb(204,143,120);
$border-color: #3a3a3a;
$font-color: #3a3a3a;
$edit-btn-bg: #E3B505;
$edit-btn-color: #ffffff;


.inviteTemplate1 {
  position: relative;
  font-family: 'Dancing Script', cursive;
  min-height: 70dvh; //debug
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  color: $font-color;
  user-select: none;
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* Chrome */
  word-wrap: break-word; /* IE */
  font-weight: 700;

  .section1 {
    position:relative;

    .line {
      display: flex;
      flex-direction: row;

      .persons {
        display: flex;
        flex:1;
        justify-content: center;
        .inner {
          margin-top: 4rem;
          font-size: 4rem;
          font-weight: 600;
          line-height: 4rem;
        }
      }

      .image1-cont {
        padding-bottom: 3rem;
      }

      .image2-cont {
        padding-top: 3rem;
      }

      .image1-cont, .image2-cont {
        display: flex;
        flex:1;
      }
      .image1-cont .frame, .image2-cont .frame {
        width: 160px;
        height: 160px;
        border-radius: 90px;
        overflow: hidden;
        border: 1px solid $base-color;
        margin:auto;

        img {
          width: 100%;
        }
      }
    }

    .text1 {
      text-align: center;
      font-size: 1.6rem;
      max-width: 60%;
      margin: 4rem auto 0 auto;

      &.style-font-size-smaller {
        font-size: 1.4rem;
      }
      &.style-font-size-normal {
        font-size: 1.6rem;
      }
      &.style-font-size-bigger {
        font-size: 1.8rem;
      }
      &.style-font-size-biggest {
        font-size: 2rem;
      }
    }

    .brideName, .groomName {
      &.style-font-size-smaller {
        font-size: 3rem;
      }
      &.style-font-size-normal {
        font-size: 4rem;
      }
      &.style-font-size-bigger {
        font-size: 4.5rem;
      }
      &.style-font-size-biggest {
        font-size: 5rem;
      }
    }

    .separator {
      &.style-font-size-smaller {
        font-size: 2rem;
        line-height: 2rem;
      }
      &.style-font-size-normal {
        font-size: 4rem;
        line-height: 4rem;
      }
      &.style-font-size-bigger {
        font-size: 4.5rem;
        line-height: 4.5rem;
      }
      &.style-font-size-biggest {
        font-size: 5rem;
        line-height: 5rem;
      }
    }

    .text2 {
      padding-top: 4rem;
      font-size: 1.6rem;
      max-width: 60%;
      margin:auto;
      text-align: center;

      &.style-font-size-smaller {
        font-size: 1.4rem;
      }
      &.style-font-size-normal {
        font-size: 1.6rem;
      }
      &.style-font-size-bigger {
        font-size: 1.8rem;
      }
      &.style-font-size-biggest {
        font-size: 2rem;
      }
    }



    .audio-player {
      margin-top: 0.5rem;
    }
  }

  .section2 {
    position:relative;
    padding-top: 2rem;

    .date-inner {
      display: flex;
      flex-direction: row;
      max-width: 80%;
      justify-content: center;
      margin: auto;

      .col-left {
        display: flex;
        flex:1;
        flex-direction: column;
        border-right: 1px solid $border-color;
        justify-content: center;
        align-content: center;
        font-size: 1.7rem;
        text-align: right;
        padding-right: 1.5rem;
      }
      .col-center {
        display: flex;
        flex-direction: column;
        border-right: 1px solid $border-color;
        justify-content: center;
        align-content: center;
        font-size: 3rem;
        font-weight: 700;
        color: $base-color;
        padding: 0 1.5rem 0 1.5rem;
      }
      .col-right {
        display: flex;
        flex:1;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        font-size: 1.4rem;
        white-space: -moz-pre-wrap; /* Firefox */
        white-space: -o-pre-wrap; /* Opera */
        white-space: pre-wrap; /* Chrome */
        word-wrap: break-word; /* IE */
        text-align: left;
        padding-left: 1.5rem;
      }

      &.inverted {
        .col-right {
          font-size: 1.7rem;
        }
        .col-left {
          font-size: 1.4rem;
          white-space: -moz-pre-wrap; /* Firefox */
          white-space: -o-pre-wrap; /* Opera */
          white-space: pre-wrap; /* Chrome */
          word-wrap: break-word; /* IE */
        }
      }
    }

    .countdown-inner {
      display: flex;
      flex-direction: row;
      max-width: 50%;
      justify-content: center;
      margin: auto;

      .countdown {
        display: flex;
        flex-direction: row;
        flex:1;
        margin-top: 1rem;

        .item {
          display: flex;
          flex:1;
          flex-direction: column;
          padding: 0.5rem;

          .value {
            display: flex;
            border: 1px solid $border-color;
            border-radius: 0.5rem;
            justify-content: center;
            font-size: 2rem;
            padding: 0.15rem;
            color: $base-color;
          }
          .title {
            padding-top: 0.2rem;
            font-size:1.2rem;
          }
        }
      }
    }

    .text1 {
      padding-top: 2rem;
      font-size: 1.6rem;
      max-width: 80%;
      margin:auto;
      &.style-font-size-smaller {
        font-size: 1.4rem;
      }
      &.style-font-size-normal {
        font-size: 1.6rem;
      }
      &.style-font-size-bigger {
        font-size: 1.8rem;
      }
      &.style-font-size-biggest {
        font-size: 2rem;
      }
    }

    .row-1 {
      display: flex;
      flex-direction: row;
      font-size: 1.2rem;
      margin-top: 0.5rem;

      .col-left {
        display: flex;
        flex:1;
        justify-content: flex-end;
        padding-right: 1rem;
      }
      .col-right {
        display: flex;
        flex:1;
        justify-content: flex-start;
        padding-left: 1rem;
      }
    }

    .text2 {
      font-size: 1.6rem;
      max-width: 80%;
      margin:0.5rem auto 0 auto;
      &.style-font-size-smaller {
        font-size: 1.4rem;
      }
      &.style-font-size-normal {
        font-size: 1.6rem;
      }
      &.style-font-size-bigger {
        font-size: 1.8rem;
      }
      &.style-font-size-biggest {
        font-size: 2rem;
      }
    }

    .text3 {
      max-width: 80%;
      margin:0.5rem auto 0 auto;
      font-size: 1.6rem;
      &.style-font-size-smaller {
        font-size: 1.4rem;
      }
      &.style-font-size-normal {
        font-size: 1.6rem;
      }
      &.style-font-size-bigger {
        font-size: 1.8rem;
      }
      &.style-font-size-biggest {
        font-size: 2rem;
      }
    }

    .text3, .text5 {
      font-size: 1.6rem;
      text-decoration: underline;
      text-underline-offset: 3px;
      &.style-font-size-smaller {
        font-size: 1.4rem;
      }
      &.style-font-size-normal {
        font-size: 1.6rem;
      }
      &.style-font-size-bigger {
        font-size: 1.8rem;
      }
      &.style-font-size-biggest {
        font-size: 2rem;
      }
    }

  }

  .section3 {
    position: relative;

    padding-top: 3rem;
    padding-bottom: 2rem;

    .locations-inner {
      margin-bottom:2rem;

      .item {
        margin-bottom: 1rem;
        .description {
          font-size: 1.3rem;
          max-width: 90%;
          margin:auto;

          .invite-location-icon {
            height: 45px;
            svg {
              height: 100%;
              color: $font-color;
            }
          }
        }

        .buttons {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: 0.3rem;
          margin-bottom: 2rem;
          font-size: 1.1rem;

          .waze {
            display: flex;
            border:1px solid $border-color;
            border-radius: 0.5rem;
            padding: 0.1rem 1rem;
            margin-right: 0.5rem;
            align-items: center;
            cursor: pointer;
          }

          .maps {
            display: flex;
            border:1px solid $border-color;
            border-radius: 0.5rem;
            padding: 0.1rem 1rem;
            margin-left: 0.5rem;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }

    .text4 {
      max-width: 70%;
      margin:0.5rem auto 0 auto;
      font-size: 1.9rem;
      font-weight: 700;
      &.style-font-size-smaller {
        font-size: 1.4rem;
      }
      &.style-font-size-normal {
        font-size: 1.6rem;
      }
      &.style-font-size-bigger {
        font-size: 1.8rem;
      }
      &.style-font-size-biggest {
        font-size: 2rem;
      }
    }

  }

  .edit-section-handler {
    position: absolute;
    top:10px;
    right: 10px;
    cursor: pointer;
    background-color: $edit-btn-bg;
    padding: 5px 10px;
    border-radius: 5px;
    color: $edit-btn-color;
    font-family: 'Signika', sans-serif;
  }
}

@media screen and (max-width:700px) {

  .inviteTemplate1 {

    .section1 {
      max-width: 100vw;

      .line {
        .persons {

          .inner {
            margin-top: 2rem;
            font-size: 3rem;
            line-height: 3rem;
            overflow: visible;
          }

        }

        .image1-cont, .image2-cont {

        }

        .image1-cont .frame, .image2-cont .frame {
          width: 80px;
          height: 80px;
          border-radius: 40px;
        }
      }

      .text1 {
        margin-top: 3rem;
      }

      .text2 {
        padding-top: 2.5rem;
      }

    }
    .section2 {

      .countdown-inner {

        .countdown {

          .item {

            .value {
              font-size: 1.5rem;
              padding: 0.5rem;
            }

          }
        }
      }

    }
  }

}
