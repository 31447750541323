.ContactUs-container{
	display: flex;
	flex-direction: column;
	height: 100dvh;

	.ContactUs-contents{
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 80dvh;
		width: 90vw;
		max-width: var(--container-max-width);
		margin: 0px auto;
		overflow: scroll;

		.Title{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 10dvh;
			color: #1E1C59;
			font-weight: bold;
			font-size: 30px;
		}

		.rs-dropdown-toggle, .rs-dropdown-toggle.rs-btn{
			width: 100%;
			color: black;
		}

		.rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu, .rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu{
			width: 100%;
			text-align: center;
		}

		.Section{
			margin-bottom: 20px;
			width: 100%;
			border-radius: 10px;
			background-color: #E5F7FE;

			.Row{
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 5px;
				margin: 10px 0px;

				.Data{
					display: flex;
					flex-direction: column;
					text-align: left;
				}
			}
		}
	}

	.ContactUs-contents::-webkit-scrollbar{
		display: none;
	}
}