.bottom-buttons{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100%;
	margin: 10px auto;

	.add-button{
		border-radius: 80px;
		width: 45%;
	}

	.back-button{
		width: 45%;
		border: 1px solid #1e2c59;
		border-radius: 80px;
	}

	.red-button{
		color: white;
		background-color: #F26157;
		border-radius: 20px;
	}
}

.add-small-button {

		display: inline-block;
		font-weight: bold;
		font-size: 15px;

	* {
		display: inline-block;
	}

	div {
		position: relative;
		top: -2px;
	}

}

.helper-modal{
	// height: 400px;
	width: 90vw;

	.modal-content{
		display: flex;
		flex-direction: column;
		justify-content: center;
		overflow-y: scroll;

		.icons{
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			height: 100px;
		}
	}

	.modal-content::-webkit-scrollbar{
		display: none;
	}

	.package-info{
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		height: 200px;
		margin-top: 20px;

		.button{
			color: white;
			border-radius: 20px;
			background-color: #00afcc;
		}
	}
}

.youtube-video iframe {
	@media screen and (max-width: 800px){
		width: 80vw;
	}
}

.show-package-data{
	display: flex;
	flex-direction: column;
	border: 1px solid black;
	color: white;
}

.data-cards{
	display: flex;
	flex-wrap: wrap;
	max-width: var(--container-max-width);

	.card{
		width: 280px;
		margin: 10px auto;
		padding: 5px 10px;
		border-radius: 10px;
		font-size: 15px;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;

		@media screen  and (max-width: 600px){
			width: 90%;
		}
	}
}

.data-table{
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
	margin-bottom: 20px;
	height: 100%;
	overflow: scroll;

	@mixin line {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		text-align: left;
		width: 100%;
		padding: 10px 20px;
		font-size: 15px
	}

	.header{
		@include line;
		font-weight: bold;
		font-size: 15px;
		background-color: #05aff2;

		.clickable-elem{
			display: flex;
			justify-content: flex-end;
			align-items: center
		}
	}

	.uneven-line{
		@include line;
		background-color: #e5f7fe;
	}

	.even-line{
		@include line;
		background-color: #d1e0ed;
	}
}

.data-table::-webkit-scrollbar{
	display: none;
}

.data-table :last-child{
	border-radius: 0px 0px 20px 20px;
}

.data-table :first-child{
	border-radius: 20px 20px 0px 0px;
}

@keyframes blinking {
	0% {
		color: red;
	}
	25% {
		color: rgba(255, 255, 255, 0);
	}
	50% {
		color: red;
	}
	75% {
		color: rgba(255, 255, 255, 0);
	}
	100% {
		color: red;
	}
}

.redBlinkingText{
	position: absolute;
	top: 10%;
	right: 50%;
	font-size: 20px;
	transform: rotate(45deg);
	color: red;
	animation: blinking 2s infinite;
}

.Edit-picture-container{
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;

  .zoom{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px 0px;

    @mixin default {
      background-color: #E8E7EE;
      font-size: 25px;
      padding: 5px 30px 0px 30px;
    }

    .left{
      @include default;
      border-radius: 20px 0px 0px 20px;
    }
    
    .right{
      @include default;
      border-radius: 0px 20px 20px 0px;
    }
  }
}

.Edit-picture2-container {
	display: flex;
	flex-direction: column;
	width: 80%;
	margin: auto;

	canvas {
		margin:auto;
	}
}