.NavBarEvents-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 99vw;
	padding: 10px 0px;
    white-space: nowrap;
	background: rgb(33, 29, 91);
	background: linear-gradient(42deg, rgba(33, 29, 91, 1) 0%, rgba(38, 91, 196, 1) 36%, rgba(0, 146, 168, 1) 68%, rgba(0, 149, 214, 1) 100%);


	.Burger-Button{
		display: flex;
		align-self: center;
		color: #ffffff;
		background-color: rgba(255, 255, 255, 0.31);
		padding: 7px;
		border-radius: 50%;
		margin-left: 10px;
		margin-right: 5px;
		position: relative;

		.Red-Dot{
			position: absolute;
			top: 1px;
			right: -5px;
			width: 15px;
			height: 15px;
			border-radius: 50%;
			background-color: red;
		}
	}
    
    .NavBarEvents-contents{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100vw;
        margin: auto;
    	overflow: auto;


		@mixin item{
			color: white;
			background-color: rgba(255, 255, 255, 0.31);
			font-size: 14px;
			margin: 0px 5px;
			padding: 5px 15px;
			border-radius: 20px;

			@media screen and (min-width: 800px){
                padding: 5px 30px;
            }
		}

        .menu-item{
			@include item;
        }

        .active-item{
			@include item;
			background-color: #05AFF2;
        }
    }

	.NavBarEvents-contents::-webkit-scrollbar {
		display: none;
	}
}

.NavBar-RedCircle-container{
	position: relative;

	.NavBar-RedCircle{
		position: absolute;
		top: 1px;
		right: 0px;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		background-color: red;
	}
}