.EventCard-container-ViewEvents{
	display: flex;
	flex-direction: column;
	min-height: 70px;
	margin: 10px 0px;
	border-radius: 10px;
	background-color: #E8E7EE;
	color: #1E1C59;
	overflow-x: hidden;

	&.walkthrough-active {
		position: relative;
		z-index: 1000000000;
		animation: zoominoutsinglefeatured 1.5s infinite;

		.row {
			.Finish, .Delete {
				display: none;
			}
		}
	}

    .EventCard-content{
		position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

		.Picture{

			border-radius: 10px 10px 0px 0px;

			img {
				width: 100%;
			}
		}

		.active-package {
			position: absolute;
			top: 0;
			padding: 5px 10px;
			border-radius: 10px 0 10px 0;

			&.free {
				background: #19b899;
				color: #FFFFFF;
			}

			&.invites {
				background: #06aff2;
				color: #FFFFFF;
			}

			&.premium {
				background: #E8630A;
				color: #FFFFFF;
			}

		}

		.Title{
			text-align: left;
			font-size: 20px;
			margin-left: 5px;
			margin-top: 10px;
		}


			.Data{
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				align-items: center;
				color: white;
				flex:1;
				height: 22px;
				font-size: 11px;
				border-radius: 5px;
				margin: 5px;
				background-color: #05AFF2;
				padding: 2px 10px;
			}




		.Full-Description{
			text-align: left;
			margin: 5px;
			font-size: 14px;
			white-space: pre-line;
			text-overflow: ellipsis;
			word-break: break-word;
		}

		.Icons::-webkit-scrollbar{
			display: none;
		}
    }

	.row.buttons {
		display: flex;
		flex-direction: row;
		margin-bottom: 5px;
		overflow-x: auto;
		overflow-y: hidden;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}




		.Share {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			min-width: 80px;
			height: 22px;
			font-size: 11px;
			border-radius: 5px;
			margin: 5px;
			padding: 2px 10px;
			color: #fff;
			background-color: #19b899;

			i {
				margin-right: 5px;
			}
		}

		.Finish {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			min-width: 140px;
			height: 22px;
			font-size: 11px;
			border-radius: 5px;
			margin: 5px;
			padding: 2px 10px;
			color: #fff;
			background-color: #E8630A;

			&.finished {
				background-color: #19b899;
			}

			i {
				margin-right: 5px;
			}
		}

		.Private {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			min-width: 120px;
			height: 22px;
			font-size: 11px;
			border-radius: 5px;
			margin: 5px;
			padding: 2px 10px;
			color: #fff;
			background-color: #70163c;

			&.finished {
				background-color: #19b899;
			}

			i {
				margin-right: 5px;
			}
		}

		.Delete {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			min-width: 80px;
			height: 22px;
			font-size: 11px;
			border-radius: 5px;
			margin: 5px;
			padding: 2px 10px;
			color: #fff;
			background-color: #e10000;

			i {
				margin-right: 5px;
			}
		}
	}
}
