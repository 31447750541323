.NavBarEvents-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 99vw;
	padding: 10px 0px;
    white-space: nowrap;
    
    .NavBarEvents-contents{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100vw;
        margin: auto;
    	overflow: auto;


		@mixin item{
			color: white;
			background-color: #05AFF267;
			font-size: 14px;
			margin: 0px 5px;
			padding: 5px 15px;
			border-radius: 20px;

			@media screen and (min-width: 800px){
                padding: 5px 30px;
            }
		}

        .menu-item{
			@include item;
        }

        .active-item{
			@include item;
			background-color: #05AFF2;
        }
    }

	.NavBarEvents-contents::-webkit-scrollbar{
		display: none;
	}
}