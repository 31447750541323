.Responses-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100dvh;
    color: #221d5d;

	.Responses-content{
		display: flex;
		flex-direction: column;
		height: 80dvh;
		// width: 90vw;
		// max-width: var(--container-max-width);
		// margin: 0px auto;
		align-items: center;
		overflow: scroll;

		.title{
			font-size: 30px;
			font-weight: bold;
			margin: 10px auto;
		}

		.options{
			display: flex;
			flex-direction: row;
			width: 90%;
			justify-content: space-between;

			@mixin button {
				width: 150px;
				padding: 10px;
				border-radius: 20px;
				color: white;
			}

			.answers{
				@include button;
				background-color: rgb(25, 184, 153);
			}

			.filters{
				@include button;
				background-color: #05aff2;
			}
		}

		.rs-input-group:not(.rs-input-group-inside){
			border: none;
			width: 90%;
		}

		.rs-input-group :first-child{
			background-color: #E5F7FE;
		}

		.rs-input-group :last-child{
			background-color: #E5F7FE;
		}
	}

	.Responses-content::-webkit-scrollbar{
		display: none;
	}

	.Responses-line-container{

		display: flex;
		flex-direction: column;
		min-height: 80px;
		// color: white;
		margin-top: 5px;
		margin-bottom: 5px;

		.Responses-line {
			align-items: center;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}
}

.SaveTheDateResponseModal {
	.data-row{
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin: 10px 0 20px 0;

		@mixin option {
			text-align: center;
			width: 30%;
			padding: 10px 5px;
			border-radius: 20px;
		}

		.active-option{
			@include option;
			background-color: #05AFF2;
			color: #FFFFFF
		}

		.inactive-option{
			@include option;
			background-color: #E5F7FE;
			color: #6B6996;
		}
	}
}

.add-response-button{
	display: flex;
	// justify-content: center;
	font-weight: bold;
	font-size: 15px;
	width: 90%;
	margin: 10px 0px;
	align-items: center;
}

.Participation-Filter{
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
	height: 200px;
	margin: 10px auto;

	.Participation-Options{
		display: flex;
		flex-direction: column;
		// justify-content: space-between;
		width: 100%;

		@mixin option {
			padding: 10px 5px;
			border-radius: 10px;
			margin-bottom: 10px;
			text-align: center;
		}

		.Active-Option{
			@include option;
			background-color: #6B6996;
			color: white;
		}

		.Option{
			@include option;
			border: 1px solid #6B6996;
		}
	}
}

.Responses-participation-modal{
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	min-width: 200px;
	max-width: 700px;
	min-height: 350px;
	max-height: 700px;
	overflow-y: auto;

	.rs-dropdown {
		border-radius: 10px;
		margin: 5px 0 10px 0 !important;
	}

	.rs-dropdown-toggle, .rs-dropdown-toggle.rs-btn{
		width: 100%;
		border-radius: 10px;
		color: #000000;
	}

	.rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu, .rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu{
		width: 100%;
	}

	.rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu, .rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu{
		padding-right: 20px;
		text-align: center;
	}
}

.Responses-participation-choice{
	margin-top: 5dvh;
	border: 1px solid black;
	padding: 10px;
	width: 100%;
}

.modal-response{
	margin-top: 10dvh;
	width: 80vw;
	max-width: 700px;
	max-height: 700px;
	overflow-y: scroll;
}
