.ResetPass-container{
    display: flex;
    flex-direction: column;
    min-height: 90dvh;
	width: 90vw;
    margin: 0px auto;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
	text-align: left;


    .ResetPass-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
		align-items: center;
        min-height: 50dvh;
    }
}

.modal-profile{
	padding-top: 30px;
	// top: 5dvh;
	text-align: center;
  margin-bottom: 20px;
}

.reset-pass-modal {
  .checkbox-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;

    text-align: left;
  }
}
