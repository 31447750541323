.rs-picker-menu .fade .in .placement-bottom .rs-picker-select-menu{
    top: 550px;
}

.modal-overlay{
    z-index:600;
}

.parinti-modal{
    width: 85vw;
    border-radius: 10px;
}


.location-modal-content{
    .modal-content{
        width: 100%;
        input{
            margin-bottom: 15px;
        }
    }
}

.location-modal {
    height: 50dvh;
    width: 85vw;
    border-radius: 10px;

    #job-select{
        background: transparent;
        color: #221d5d;
        border: none;
        border-radius: 0px;
        border-bottom: 1px solid #221d5d;
        width : 100%;
        display: flex;
        flex-direction: row;
        font-family: 'Signika', sans-serif;
        font-weight: 700;
        font-style: normal;
    }
}

.nasi-modal{
    width: 85vw;
    border-radius: 10px;

    .modal-content{
        input{
            margin-bottom: 15px;
        }
    }
}
