.PublicEvent-container{
    height: 100dvh;
    display: flex;
    flex-direction: column;
    background-color: white;

	.PublicEvent-contents{
		display: flex;
		flex-direction: column;
		height: 80dvh;

		.social-login-container {
			display: flex;
			flex-direction: row;
		}

		.social-login-button {
			flex: 1;
			background-color: white;
			border: 1px solid rgba(34, 29, 93, 0.39);
			border-radius: 5px;
			padding: 8px 10px;
			cursor: pointer;
			font-size: 15px;
			color: #fff;
		}

		.social-login-button i {
			padding-right: 5px;
			font-size: 18px;
			position: relative;
			top: 2px;
		}

		.social-facebook-login {
			background-color: rgb(58, 89, 153);
		}

		.social-google-login {
			background-color: rgb(221, 76, 57);
		}

		.social-apple-login {
			background-color: rgb(243, 243, 243);
			color: #000;
		}

		.PageContent{
			margin: 10px auto;
			border-radius: 10px;
			overflow-y: scroll;
			height: 100%;
			max-width: var(--container-max-width);
			width: 90vw;
		}

		.PageContent::-webkit-scrollbar{
			display: none;
		}
	}

	.PublicEvent-contents::-webkit-scrollbar{
		display: none;
	}

	.side-bar{
		position: fixed;
		background-color: #221d5d;
		display: flex;
		flex-direction: column;
		// justify-content: space-between;
		color: white;
		top: 10%;
		width: 70vw;
		height: 90dvh;
		z-index: 2000;
		overflow-y: scroll;
	}

	.side-bar-line{
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		margin-top: 8px;
		margin-bottom: 8px;

		.side-bar-item{
			display: flex;
			align-items: center;
			width: 60%;
			height: 40px;
		}

		.side-bar-item-active{
			display: flex;
			align-items: center;
			color: #1DE9B6;
			width: 60%;
			height: 40px;
		}
	}

	.side-bar-blur{
		position: fixed;
		background-color: #00000080;
		top: 10%;
		left: 70%;
		width: 30vw;
		height: 90dvh;
		z-index: 2000;
	}
}