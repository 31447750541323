.AlbumsTab-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    width: 90vw;
    max-width: var(--container-max-width);
    margin: 0px auto;
    border-radius: 10px;
    background-color: white;

    .AlbumsTab-contents-image{
        width: 90%;
        margin: auto;

        .AlbumsTab-Card{
			display: flex;
			flex-direction: row;
			border: 2px solid black;

			.AlbumsTab-content{
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding:10px;
				padding-left: 30px;
			}
		}
    }
}