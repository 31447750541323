.NasiComponent-container{

    .NasiComponent-content{


        .Display {
            .nas-element{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 10px 20px;
            

                #nume{
                    font-size: 20px;
                }
             
            }
        }
        
    }
}