.terms-modal{

    height:80dvh;
    width:90vw;
    border-radius: 10px;
    overflow-y: scroll;

    &.pb {
        height: 80dvh;
        margin-top: 3dvh
    }
}

.confid-modal{


}
