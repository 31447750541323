.GuestInfoPage-container{
    font-family: 'Signika', sans-serif;
    font-weight: 500;
    font-style: normal;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100dvh;
    background-color: white;
    color: #00B7AD;

	.Title{
		display: flex;
		align-items: center;
		height: 10dvh;
		color: #1E1C59;
		font-weight: bold;
		font-size: 30px;
	}

    .GuestInfoPage-contents{
        display: flex;
        flex-direction: column;
        width: 90vw;
		height: 70dvh;
		overflow-y: scroll;
    }

	.GuestInfoPage-contents::-webkit-scrollbar{
		display: none;
	}
}