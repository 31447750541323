.ContactUs-container{
    display: flex;
    flex-direction: column;
    min-height: 70dvh;
    margin: 0px auto;
    border-radius: 10px;
    background-color: white;

	.ContactUs-TopText{
		text-align: center;
	}

    .ContactUs-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 50dvh;

		.Section{
			margin-bottom: 20px;
			width: 100%;
			border-radius: 10px;
			background-color: #E5F7FE;

			.Row{
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 5px;
				margin: 10px 0px;

				.Data{
					display: flex;
					flex-direction: column;
					text-align: left;
				}
			}
		}
    }
}