.EditMenu-container{
    display: flex;
    flex-direction: column;
    height: 100dvh;
    background-color: white;

    .EditMenu-contents{
        display: flex;
        flex-direction: column;
        width: 100vw;
		max-width: var(--container-max-width);
		height: 80dvh;
        margin: auto;
	
		.title{
			width: 100%;
			color: #1E1C59;
			font-size: 30px;
			font-weight: bold;
		}

		.EditMenu-MenuData{
			display: flex;
			flex-direction: column;
			height: auto;
			overflow-y: scroll;

			.Uploader{
				margin: 20px auto;
				border: 1px solid #221d5d;
				border-radius: 5px;
			}

			.rs-uploader-text .rs-uploader-file-items{
				margin-top: 0px;
			}

			.Buttons{
				display: flex;
				flex-direction: row;
				justify-content: center;
			}

			.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn, .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn{
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100px;
			}
		}

		.EditMenu-MenuData::-webkit-scrollbar {
			display: none;
		}
    }
}