.rs-drawer-body {
  display: flex;
  align-items: center;
  font-family: Signika, sans-serif;
  font-weight: 500;
  font-style: normal;

  .Login-drawer-contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100vw;

    #ind {
      font-size: 17px;
      text-align: center;
      margin-bottom: 20px;
    }

    .Login-button {
      background-color: #00B7AD;
      width: 100%;
      height: 48px;
      color: white;
      font-weight: 900;
      border-radius: 15px;
      font-size: 20px;
      margin-top: 40px;
    }
  }
}

.loading-overlay {
  position:absolute;
  top:0;
  left:0;
  width: 100vw;
  height: 100dvh;
  z-index: 10000;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.7);
}

.Login-container {
  background-color: white;
  font-family: Signika, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #221d5d;
  height: 90dvh;
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .password-row {
    position: relative;

    .password-show-btn {
      position: absolute;
      right: 2px;
      top: 1px;
      z-index: 100;
      padding: 7px 11px 7px 11px;
      //background: #E5F7FE;
      cursor: pointer;
      border-radius: 8px;

      &.active {
        color: #c20000;
      }
    }
  }

  .Go-To {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 60px;
    justify-content: space-evenly;
    background-color: #221d5d;
    color: white;
  }

  .checkbox-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
  }

  .Login-one {
    margin-top: 40px;
  }

  .Login-three {
    margin-bottom: 20dvh;
  }

  @media screen and (min-width: 765px) {
    width: 35vw;
  }

  .Logo-one {
    #logo {
      width: 120px;
      height: 120px;

      img {
        width: 120px;
        height: 120px;
      }
    }
  }

  #ondemand-text {
    margin-top: -7px;
  }

  #recovery-pass {
    margin-top: 15px;
    text-decoration: underline;
  }

  #create-account {
    text-decoration: underline;
    color: #00B7AD;
  }

  .InputGroup-Login {
    color: #221d5d;
    background-color: #E5F7FE;
    margin-bottom: 20px;
  }

  .Login-button {
    background-color: #00B7AD;
    width: 100%;
    height: 48px;
    color: white;
    font-weight: 900;
    border-radius: 15px;
    font-size: 20px;
  }

  .social-login-container {
    display: flex;
    flex-direction: row;
  }

  .social-login-button {
    flex: 1;
    background-color: white;
    border: 1px solid rgba(34, 29, 93, 0.39);
    border-radius: 5px;
    padding: 8px 10px;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
  }

  .social-login-button i {
    padding-right: 5px;
    font-size: 18px;
    position: relative;
    top: 2px;
  }

  .social-facebook-login {
    background-color: rgb(58, 89, 153);
  }

  .social-google-login {
    background-color: rgb(221, 76, 57);
  }

  .social-apple-login {
    background-color: rgb(243, 243, 243);
    color: #000;
  }

  .social-or-container {
    display: flex;
    flex-direction: row;
    height: 20px;
    margin: 10px 0 20px 0;

    .left {
      flex:1;
    }
    .center {
      flex:1;
      font-size: 16px;
      color: rgba(34, 29, 93, 0.69);
    }
    .right {
      flex:1;
    }

    .line {
      height: 1px;
      background-color: rgba(34, 29, 93, 0.69);
      margin-top:10px;
    }
  }
}
