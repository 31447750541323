.Administrators-container{
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-family: 'Signika', sans-serif;
  font-weight: 700;
  font-style: normal;

  .Administrators-contents{
    display: flex;
    flex-direction: column;
    height: 79dvh;
    width: 90vw;
    max-width: var(--container-max-width);
    margin: 0px auto;

    .data-table {
      width: 100%;
    }

    .btn-remove-access {
      color: red;
      padding: 5px 10px 5px 10px;
      cursor: pointer;
    }
  }

  .Administrators-contents::-webkit-scrollbar{
    display: none;
  }

}

.administrators-modal-inner {
  padding: 2rem 0 2rem 0;
  text-align: center;

  #icons{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    #whatsapp{
      width: 70px;
    }
    #messenger{
      width: 60px;
    }
  }

  .bottom-buttons .add-button {
    width: unset;
  }
}