.Signup-container{
    background-color: white;
    font-family: Signika, sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #221d5d;
    min-height: 90dvh;
	width: 90vw;
	max-width: var(--container-max-width);
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;

    .checkbox-content{
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
    }

    .rs-picker-toggle-caret{
        display: none;
    }

    .DatePicker-container{
        width: 100%;
        margin-bottom: 30px;
        border: none;
        border-bottom: solid #221d5d 2px;
        color: #221d5d;
    }

    .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
        color: #221d5d;
    }

    .rs-picker-toggle{
        padding-left: 35px;
        font-family: Signika, sans-serif;
        font-weight: 500;
        font-style: normal;

        .rs-picker-toggle-placeholder{
            color: #221d5d;
        }
    }

    #disclaimer{
        font-size: 11px;
    }
   
    .Selecter-container{
        border: none;
        margin-bottom: 30px;
        border-bottom: solid #221d5d 2px;
        width: 100%;
    }

    .Signup-one{
        text-align: left;
        margin-bottom: 30px;
    }

    .Signup-three{
        text-align: center;
        margin-top: 30px;
    }

    .InputGroup-Container{
        width: 300;
        width: 100%;
        margin-bottom: 30px;
        border-bottom: solid #221d5d 2px;
    }

    .InputGroup-Login{
        color: #221d5d;
        border: none;
        font-size: 15px;
    }

    .InputGroup-Login::placeholder{
        color: #221d5d;
    }

    .Continue-button{
        background-color: #00B7AD;
        width: 100%;
        height: 48px;
        color: white;
        font-weight: 900;
        border-radius: 15px;
        font-size: 20px;
        cursor: pointer;
    }
}