.EditSaveTheDate-container{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100dvh;
	color: #221d5d;
    font-weight: 700;

	.Footer{
		display: flex;
		justify-content: space-around;
        width: 100%;
        margin: 20px auto;
	}

	#title{
		color: #221d5d;
        font-size: 25px;
		font-weight: 800;
	}

    .EditSaveTheDate-contents{
        display: flex;
        flex-direction: column;
        height: 79dvh;

		.data-zone{
            display:flex;
            flex-direction: column;
            text-align: center;
			overflow-y: scroll;
            width: 90vw;
			max-width: var(--container-max-width);
            margin: 0px auto;
            color: #221d5d;

			.ChangeChoiceButton{
				width: 70%;
				text-align: center;
				background-color: #E3B505;
				margin:10px auto 30px auto;
				padding: 5px;
				border-radius: 20px;
				color: white;
			}

			.Section{
				margin-top: 20px;

				.Title{
					text-align: center;
				}

				.Options-Row{
					display: flex;
					flex-direction: row;
					justify-content: center;
					margin: 10px 0px;
		
					@mixin option {
						width: 50px;
						padding: 10px;
						border-radius: 20px;
					}
		
					.active-option{
						@include option;
						background-color: #05AFF2;
						color: #FFFFFF
					}
		
					.inactive-option{
						@include option;
						background-color: #E5F7FE;
						color: #6B6996;
					}
				}
			}

			.Input_Type_Options{
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				flex-wrap: wrap;
				overflow-y: scroll;

				.Input_Type_Option{
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					font-size: 15px;

					.Circle{
						display: flex;
						justify-content: center;
						align-items: center;
						height: 100px;
						width: 100px;
						margin: 10px 50px;
						border-radius: 50%;
						background-color: #E5F7FE;
					}
				}
			}

			.Input_Type_Options::-webkit-scrollbar{
				display: none;
			}

			.Invite-Design {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin: 10px 0px;

				.color-container{
					text-align: center;
					display: flex;
					flex-direction: column;
					align-items: center;

					.color-choice{
						display: flex;
						flex-direction: row;

						.styles_closeButton__20ID4{
							display: none;
						}
					}
				}
			}

			.input-section{
				text-align: center;

				.rs-uploader-text .rs-uploader-file-items{
					margin-top: 0px;
				}
			}
		}

		.Upload-Audio{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.Buttons{
				display: flex;
				flex-direction: row;
				justify-content: center;
				margin: 10px 0px;
			}
		}

		.data-zone::-webkit-scrollbar{
			display: none;
		}
    }
}