.AddTablesData-container{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100dvh;
	width: 100vw;
	color: #1E1C59;

	.Title{
		width: 90%;
		color: #1E1C59;
		font-size: 30px;
		font-weight: bold;
		margin: 20px auto;
	}

	.AddTablesData-contents{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: calc(80dvh - 100px);
		width: 90vw;
		max-width: var(--container-max-width);
		margin: 0 auto;

		.AddTablesData-TableType{
			display: flex;
			flex-direction: row;
			justify-content: center;
			border-radius: 10px;
			background-color: #E5F7FE;
			overflow: hidden;

			@mixin table-data {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 50%;
				height: 50px;
				color: #6B6996;
			}

			.tableType{
				@include table-data;
			}

			.active-tableType{
				@include table-data;
				border-radius: 10px;
				background-color: #05AFF2;
				color: white;
			}
		}

		.AddTablesData-Data{
			display: flex;
			flex-direction: column;
			height: 200px;
			overflow-y: scroll;
		}

		.Buttons{
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin-bottom: 20px;
		}
	}
}