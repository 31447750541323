// .rs-uploader-trigger input[type='file']{
//     width:10vw;
// }

.EditEvent-container{
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Signika', sans-serif;
    font-weight: 700;
    font-style: normal;

    .Footer{
		display: flex;
		flex-direction: row;
		justify-content: space-around;
        width: 90%;
        margin: 10px auto;

        .add-button{
            border-radius: 80px;
            width: 40%;
        }

		.back-button{
			width: 40%;
			border: 1px solid #1e2c59;
			border-radius: 80px;
		}

        #disclaimer{
            font-size:10px;
        }
    }
    
    .EditEvent-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 80dvh;

        .input-zone{
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            width: 90vw;
			max-width: var(--container-max-width);
			height: auto;
			overflow-y: scroll;
            margin: 0px auto;

            .InputGroup-Container{
                width: 300px;
                width: 100%;
                margin: 10px 0px;
                // border-bottom: solid #221d5d 1px;
            }

			.rs-uploader-text .rs-uploader-file-items{
				margin-top: 0px;
			}
        
            .rs-picker-toggle .rs-picker-toggle-caret{
                display: none;
            }

            .rs-picker-toggle .rs-picker-toggle-placeholder{
                color: #221d5d;
                padding-left: 0px;
                font-family: 'Signika', sans-serif;
                font-weight: 700;
                font-style: normal; 
            }

            .AddJob-Icon{
                color: #221d5d;
            }

            #job-select::placeholder{
                padding-left:10px;
            }

            #job-select{
                background: transparent;
                color: #221d5d;
                border: none;
                border-radius: 0px;
                // border-bottom: 1px solid #221d5d;
                width : 100%;
                display: flex;
                flex-direction: row;
                font-family: 'Signika', sans-serif;
                font-weight: 700;
                font-style: normal;
            }

            .rs-input{
                color: #221d5d;
            }

            .rs-input::placeholder{
                color: #221d5d;
            }

            .AddHours-date-picker{
                display: flex;
                flex-direction: column;
				text-align: left;
				
				.Overlay{
					position: absolute;
					top: -10px;
					margin-left: 10px;
					padding: 0px 5px;
					font-size: 12px;
					color: black;
					background-color: white;
				}

				.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean, .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean{
					display: none !important;
				}

				.rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn, .rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn{
					background-color: #E5F7FE;
				}
            }

            .AddJob-Container{
                margin-bottom: 10px;

                .rs-picker-toggle-value{
                    font-family: 'Signika', sans-serif;
                    font-weight: 700;
                    font-style: normal;
                    color: #221d5d;
                }

                .rs-picker-toggle .rs-picker-toggle-placeholder{
                    color: #221d5d;
                    // padding-left:25px;
                }
            }

            #input{
                background: transparent;
                color: #221d5d;
                border: none;
                border-radius: 0px;
            }

            #inputText{
                height: 13dvh;
                padding-left:35px;
            }

            #input::placeholder{
                color: #221d5d;
            }

            #inputText::placeholder{
                color: #221d5d;
            }

			

			.Locations{
				.location{
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 100%;
					margin: 20px 0px;
				
					@mixin line {
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						text-align: left;
						width: 100%;
						padding: 10px 20px;
						font-size: 15px;
						text-align: center;
					}
				
					.header{
						@include line;
						font-weight: bold;
						font-size: 15px;
						background-color: #05aff2;
					}
				
					.uneven-line{
						@include line;
						background-color: #e5f7fe;
					}
				
					.even-line{
						@include line;
						background-color: #d1e0ed;
					}
				}
				
				.location :first-child{
					border-radius: 20px 20px 0px 0px;
				}
				
				.location :last-child{
					border-radius: 0px 0px 20px 20px;
				}
			}
        }

		.input-zone::-webkit-scrollbar{
			display: none;
		}

        #title{
            color: #221d5d;
            font-size: 35px;
            // text-align: left;
        }

        #sau{
            margin: 10px 0px;
        }

        .event-code{
            font-size: 17px;
            font-weight: 700;
        }

        h4{
            width:80%;
            margin: 2dvh auto;
            color: #221d5d;
        }
    }
}

textarea.rs-input{
	height: 100px !important;
}