.Participant-container{
    display: flex;
    flex-direction: column;
    height: 70dvh;
	width: 100%;
    margin: 0px auto;
    border-radius: 10px;
	text-align: left;
	overflow-y: scroll;

	.user-section {
		margin-bottom: 30px;
	}

	.details-cont {
		text-align: left;
	}
	.details-item {
		width: 100%;
		display: inline-block;
	}

	.image-preview {
		width: 130px;
		height: 130px;
		border-radius: 150px;
		overflow: hidden;
		border: 1px solid #ccc;
		margin:1rem auto 0 auto;
	}


	.Row {
		display: flex;
		flex-direction: row;
		margin-top: 15px;
		align-items: center;
		overflow-wrap: anywhere;

	}

    .Participant-buttons{
        display: flex;
		flex-direction: row;
		width: 90%;
        justify-content: space-between;
		margin-top: 10px;
    }

	.bottom-message{
		display: flex;
		flex-direction: column;
		color: #1E1C59;
		text-align: center;

		.title{
			font-weight: bold;
			font-size: 16px;
		}
	}
}

.Participant-container::-webkit-scrollbar{
	display: none;
}

.rs-dropdown-menu{
	padding: 0px !important;
}

.rs-dropdown-toggle, .rs-dropdown-toggle.rs-btn{
	color: #221d5d;
}

.rs-dropdown-menu .rs-dropdown-item-content{
	background-color: #E5F7FE;
	color: #221d5d;
}

.rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content{
	background-color: #05AFF2;
	color: #FFFFFF;
}

.dropdown_scroll ul{
	max-height: 200px;
	overflow: auto;
}

@media screen and (min-width: 550px){
		.Participant-container {
			.details-item {
				width: 50%;
			}
		}
}