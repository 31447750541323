.AddMenu-container{
    display: flex;
    flex-direction: column;
    height: 100dvh;
    background-color: white;

    .AddMenu-contents{
        display: flex;
        flex-direction: column;
        width: 100vw;
		max-width: var(--container-max-width);
		height: 80dvh;
        margin: auto;
		overflow-y: scroll;
	
		.title{
			width: 100%;
			color: #1E1C59;
			font-size: 30px;
			font-weight: bold;
		}

		.Uploader{
			margin: 20px auto;
			border: 1px solid #221d5d;
			border-radius: 5px;
		}

		.rs-uploader-text .rs-uploader-file-items{
			margin-top: 0px;
		}

		.Buttons{
			display: flex;
			flex-direction: row;
			justify-content: center;
			padding: 0 10px 0 10px;
		}

		.rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn, .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn{
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100px;
		}
    }

	.AddMenu-contents::-webkit-scrollbar {
		display: none;
	}
}