.GiftTab-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70dvh;
    width: 90vw;
    max-width: var(--container-max-width);
    margin: 0px auto;
    border-radius: 10px;
    background-color: white;

    .GiftTab-Content{
        display: flex;
        flex-direction: column;
        height: auto;
        min-height: 40dvh;
		margin: 0px auto;
        width: 100%;
        overflow-y: scroll;

        .GiftTab-Card{
			display: flex;
			flex-direction: column;
			width: 100%;
			max-width: var(--container-max-width);
			background-color: #e8e7ee;
			color: #1e1c59;
			border-radius: 10px;
			margin: 20px auto;
			text-align: left;

			.Picture{
				height: 150px;
				width: 100%;
			}

			.Title{
				font-size: 20px;
				margin-left: 5px;
				margin-top: 10px;
			}

			.Description{
				text-align: left;
				margin: 5px;
				font-size: 14px;
				white-space: pre-line;
				text-overflow: ellipsis;
				word-break: break-word;
			}

			.Link{
				margin-left: 5px;
				color: #05AFF2;
			}

			.Options{
				margin-left: 5px;
			}
		}

		.GiftTab-Card :first-child{
			border-radius: 10px 10px 0px 0px;
		}
    }

	.GiftTab-Content::-webkit-scrollbar{
		display: none;
	}
}