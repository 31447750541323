.PopularityVotes-container{
	display: flex;
	flex-direction: column;
	height: 70dvh;
	margin: 0px auto;
	background-color: white;
	text-align: left;

	.PopularityVotes-contents{
		display: flex;
		flex-direction: column;
		height: calc(70dvh - 150px);
		overflow-y: scroll;

		.PopularityVotes-candidates{
			display: flex;
			flex-direction: row;
			align-items: center;
			height: 100px;
			width: 90%;
		}
	}

	.PopularityVotes-contents::-webkit-scrollbar{
		display: none;
	}
}