.MyEvents-Filter{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 90%;
	align-items: center;
	background-color: rgb(232, 231, 237);
	border-radius: 10px;
	margin: 0px auto;
	padding: 5px;
	overflow: scroll;

	@mixin css($color: white) {
		border-radius: 10px;
		font-size: 12px;
		padding: 6px;
		color: $color
	}

	.Events-Status-Option{
		@include css($color: grey);
		width: 50%;
	}

	.Events-Status-Choice{
		@include css();
		width: 50%;
		background-color: rgb(6, 175, 242);
	}
}

.MyEvents-Filter::-webkit-scrollbar{
	display: none;
}

.MyEvents-contents{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
	overflow-y: scroll;
    height: calc(80dvh - 70px);

	.search-box {
		top:0;
		position: absolute;
		right: 10px;
		font-size: 12px;

		.search-icon {
			font-size: 12px;
		}
	}

	.MyEvents-Card-Container{
		display: flex;
		flex-direction: column;
		min-height: 70px;
		max-width: 50vw;
		margin: 10px auto;
		border-radius: 10px;
		background-color: #E8E7EE;
		color: #1E1C59;

		@media screen and (max-width: 800px){
			max-width: 100vw;
			margin: 10px;
		}

		.Picture{
			height: 150px;
			width: 100%;
		}

		.Open-Event{
			display: flex;
			justify-content: center;
			background-color: #05AFF2;
			color: white;
		}

		.Title{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin-left: 5px;
			margin-top: 10px;

			.Text{
				text-align: left;
				width: 70%;
				font-size: 20px;
			}

			.FavoriteIcon{
				display: flex;
				width: 30%;
				justify-content: center;
				align-items: center;
				color: grey;
			}

			.FavoriteIcon-Filled{
				display: flex;
				width: 30%;
				justify-content: center;
				align-items: center;
				color: red;
			}
		}

		.Icons{
			display: flex;
			flex-direction: row;
			align-items: center;
			overflow: scroll;
			margin: 0px 5px;

			@mixin event{
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				align-items: center;
				color: white;
				min-width: 80px;
				height: 22px;
				font-size: 11px;
				border-radius: 5px;
				margin: 2px;
				padding: 1px 3px;
			}

			.Data{
				@include event;
				background-color: #05AFF2;
				padding: 2px 10px;
			}

			.New{
				@include event;
				background-color: red;
				padding: 2px 10px;
			}

			.PublicEvent{
				@include event;
				background-color: #2879F6;
			}

			.PrivateEvent{
				@include event;
				background-color: #70163C;
			}

			.Delete{
				@include event;
				border: 1px solid #F26157;
				color: #F26157;
			}

			.Share{
				@include event;
				background-color: #19B899;
			}
		}

		.Description{
			text-align: left;
			font-size: 14px;
			margin: 5px;
			max-width: 90%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.Full-Description{
			text-align: left;
			margin: 5px;
			font-size: 14px;
			white-space: pre-line;
			text-overflow: ellipsis;
			word-break: break-word;
		}

		.Icons::-webkit-scrollbar{
			display: none;
		}
	}


	.MyEvents-Card-Container :first-child{
		border-radius: 10px 10px 0px 0px;
	}
}

.MyEvents-contents::-webkit-scrollbar{
	display: none;
}