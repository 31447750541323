.SeatingPlan-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 90dvh;
    margin: 0px auto;
    border-radius: 10px;
    background-color: white;

    .SeatingPlan-contents{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 50dvh;
        
    }
}