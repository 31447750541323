.body{
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: left;

	.question{
		width: 80%;
		max-width: 500px;
		text-align: center;
		margin-bottom: 30px;
	}

	.buttons{
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
	}
}

.customModalDisableScroll {
	overflow-x: unset !important;
	margin-bottom: 10rem;
}