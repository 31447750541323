.package-card {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  min-height: 18.5rem;
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgb(213, 224, 255) 100%);
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  padding: 1rem;
  cursor: pointer;

  &.current {
    background: rgb(0,204,82);
    background: linear-gradient(-145deg, rgba(0,204,82,1) 0%, rgba(23,185,107,1) 50%, rgba(31,141,21,1) 100%);
    color: #ffffff;

    .package-footer {
      .package-footer-left {
        .see-more-cont {
          color: #ffffff;
          font-weight: 400;
        }
      }
    }
  }

  &.selected {
    background: rgb(0,148,204);
    background: linear-gradient(-145deg, rgba(0,148,204,1) 0%, rgba(23,112,185,1) 50%, rgba(33,31,94,1) 100%);
    color: #ffffff;
    cursor: pointer;

    .package-footer {
      .package-footer-left {
        .see-more-cont {
          color: #ffffff;
          font-weight: 400;
        }
      }
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }

  .package-check-cont {

  }

  .package-current-badge {
    background-color: #ffffff;
    border-radius: 0.8rem;
    width: fit-content;
    padding: 0.1rem 0.5rem;
    font-size: 0.75rem;
    margin: 0 0 1rem 0;
    color: #1E1C59;
  }

  .package-title {
    display: flex;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 1rem;
    flex:1;
    align-items: center;
  }

  .package-description {
    display: flex;
    flex-direction: column;

    span {
      font-size: 0.8rem;
    }
  }

  .package-footer {
    display: flex;
    flex-direction: row;

    .package-footer-left {
      flex:1;
      display: flex;
      flex-direction: column;
      // eslint-disable-next-line
      justify-content: flex-end;

      .see-more-cont {
        cursor: pointer;
        text-decoration: underline;
        font-size: 0.8rem;
        font-weight: 500;
        margin-top: 1rem;
        color: #1E1C59;
      }
    }
    .package-footer-right {
      flex:1;

      .package-current-price {
        font-size: 1.5rem;
        font-weight: 600;
        text-align: end;

        .old-price {
          font-size: 1.2rem;
          color: #ce3b3b;
          text-decoration: line-through;
        }
      }

    }

  }
  .Options-Row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px 0px;


    .active-option{
     // width: 50px;
      padding: 10px;
      border-radius: 0.5rem;
      background-color: #05AFF2;
      color: #FFFFFF;
      width: 100%;
      text-align: center;
    }
    .inactive-option{
      width: 100%;
      text-align: center;
    //  width: 50px;
      padding: 10px;
      border-radius: 0.5rem;
      background-color: #E5F7FE;
      color: #6B6996;
    }
  }
}


@media screen and (min-width: 1200px) {

  .package-card {
    max-width: 500px;
  }

}
