.Tickets-container-p{
	display: flex;
	flex-direction: column;
	height: 70dvh;
	margin: 0px auto;
	border-radius: 10px;
	background-color: white;
	overflow-y: auto;

	.social-login-container {
		display: flex;
		flex-direction: row;
	}

	.social-login-button {
		flex: 1;
		background-color: white;
		border: 1px solid rgba(34, 29, 93, 0.39);
		border-radius: 5px;
		padding: 8px 10px;
		cursor: pointer;
		font-size: 15px;
		color: #fff;
	}

	.social-login-button i {
		padding-right: 5px;
		font-size: 18px;
		position: relative;
		top: 2px;
	}

	.social-facebook-login {
		background-color: rgb(58, 89, 153);
	}

	.social-google-login {
		background-color: rgb(221, 76, 57);
	}

	.social-apple-login {
		background-color: rgb(243, 243, 243);
		color: #000;
	}

	.Tickets-tab-options{
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-evenly;
		font-size: 25px;
		height: 75px;
	}

	.Tickets-content{
		display: flex;
		flex-direction: column;
		width: 100%;

		.ticket{
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			height: 400px;
			width: 300px;
			margin: 20px auto;
			border: 5px solid #221d5d;
			border-radius: 10px;

			.ticket-state{
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;
				align-self: center;
				width: 140px;
			}
		}

		.button{
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: space-evenly;
		}
	}
}

.Tickets-container::-webkit-scrollbar{
	display: none;
}
