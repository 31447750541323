.Packages-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100dvh;
  width: 100vw;

  .Packages-contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    overflow: scroll;
    height: 80dvh;
    width: 90vw;
    max-width: var(--container-max-width);
    margin: 0px auto;

    &::-webkit-scrollbar{
      display: none;
    }
    .packages-voucher-cta {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: 1rem 0 0 0;
      width: 100%;
      max-width: 1001px;

      span {
        text-decoration: underline;
        cursor: pointer;
        color: #1e1c59;
        font-weight: 400;
        font-size: 1rem;
      }

    }
    .packages-cont {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem 0;
      width: 100%;
      padding: 1rem 0;
    }

    .show-all-packages {
      text-decoration: underline;
      cursor: pointer;
      margin: 0 auto 2rem auto;
    }

    .go-to-checkout-button {
      position: absolute;
      top: calc(90dvh - 58px);
      z-index: 4;
      background-color: #19B899;
      color: #ffffff;
      border-radius: 20px;
      padding: 12px 12px;
      font-size: 14px;
      text-align: center;
      left:5vw;
      width: 90vw;
      cursor: pointer;
    }

    .checkout-form {
      width:100%;
      max-width: 500px;
      margin: 0 auto 0 auto;

      .invoicing-type-cont {
        display: flex;
        flex-direction: row;
        margin-bottom: 30px;

        .item {
          flex:1;
          border: 1px solid rgba(30, 28, 89, 0.27);
          border-radius: 5px;
          padding: 8px 10px;
          cursor: pointer;

          &.active {
            background: rgb(5, 175, 242);
            color: #ffffff;
          }

          &:first-of-type {
            margin-right: 5px;
          }
          &:last-of-type {
            margin-left: 5px;
          }
        }
      }

      .select-picker-item {
        padding-bottom: 0.5rem;

      }
    }

  }
}

@media screen and (min-width: 1200px) {
  .Packages-container {
    .Packages-contents {
      max-width: 1100px;
      align-items: center;

      .packages-cont {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem 1rem;
        justify-content: center;
      }

      .go-to-checkout-button {
        min-width: 200px;
        max-width: 600px;
        width: 100%;
        left:unset;
      }
    }
  }
}

.package-offer-description {
  margin-bottom: 30px;
  padding: 0 10px 0 10px;
  font-size: 1.1rem;
}

.Package-countdown {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 40px;
  justify-content: center;

  .item {

    .value {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      border-radius: 10px;
      background-color: #221d5d;
      color: #FFFFFF;
      font-weight: 700;
      font-size: 17px;
    }

    .title {
      text-align: center;
      margin-right: 10px;
    }

  }

}
